import { DatabaseReference } from "firebase/database";
import { Database } from "..";
import { FirebaseDatabase } from "../core";
import { User } from "../model/lobby";
import { Reference } from "@firebase/database-types";

export function getUsersDb(
  getDbRef: () => DatabaseReference | Reference
): Database<User, string> {
  return new FirebaseDatabase<User, string>(getDbRef, "players");
}
