import { DatabaseReference } from "firebase/database";
import { Database } from "..";
import { FirebaseDatabase } from "../core";
import { FishbowlGame } from "../model/fishbowl";
import { Reference } from "@firebase/database-types";

export function getFishbowlGameDb(
  getDbRef: () => DatabaseReference | Reference
): Database<FishbowlGame, string> {
  return new FirebaseDatabase<FishbowlGame, string>(getDbRef, "fishbowlGames");
}
