import { CodenamesPlayer, CodenamesConfig } from "./codenames";
import { FishbowlConfig, FishbowlPlayer } from "./fishbowl";

export interface Games<TGame = GenericGame> {
  [gameId: string]: TGame;
}

export interface GenericGame<TPlayer = Player> {
  name: string;
  host: string;
  players: Players<TPlayer & Player>;
}

export interface Players<TPlayer = Player> {
  [key: string]: TPlayer;
}

export interface Player {
  displayName: string;
}

export interface ActivePlayer {
  isActive: boolean;
}

// TODO: remove this and incorporate it into GameType
export enum LobbyGameType {
  Lobby = -1,
}

export type GameAssignmentType = GameType | LobbyGameType;

export interface DeviceToken {
  token: string;
  lastRetrievedTimestamp: number;
}
export interface User {
  activeGameId?: string;
  games?: { id: string; type: GameAssignmentType }[];
  deviceToken?: DeviceToken;
}

export interface Users {
  [key: string]: User;
}

export interface SingleUseAccount {
  isSingleUse: true;
  email: string;
  password: string;
}

export type Account = SingleUseAccount | { isSingleUse: false };

export interface LobbyPlayer {
  displayName: string;
}

export enum GameType {
  Fishbowl,
  Codenames,
}

export interface GameTypeConfig<
  T extends GameType = GameType,
  C extends GameConfig<GameType> = GameConfig<T>
> {
  gameType: T;
  config: C;
}

export function isFishbowlLobbyGame(
  game: LobbyGame
): game is LobbyGame<GameType.Fishbowl> {
  return game.gameType === GameType.Fishbowl;
}

export function isFishbowlTypeConfig(
  gameTypeConfig: GameTypeConfig
): gameTypeConfig is GameTypeConfig<GameType.Fishbowl> {
  return gameTypeConfig.gameType === GameType.Fishbowl;
}

export type GameConfig<T extends GameType> = T extends GameType.Fishbowl
  ? FishbowlConfig
  : T extends GameType.Codenames
  ? CodenamesConfig
  : never;

export enum ConfigType {
  Numeric,
  Boolean,
  Unconfigureable,
}

export interface GameConfigSetting {
  name: string;
  tooltip: string;
  configType: ConfigType;
}

export interface BooleanGameConfigSetting extends GameConfigSetting {
  configType: ConfigType.Boolean;
}

export interface NumericGameConfigSetting extends GameConfigSetting {
  configType: ConfigType.Numeric;
  minimum: number;
}

export type GameConfigSettings<
  T extends FishbowlConfig | CodenamesConfig = FishbowlConfig | CodenamesConfig
> = {
  [K in keyof T]: T[K] extends number
    ? NumericGameConfigSetting
    : T[K] extends boolean
    ? BooleanGameConfigSetting
    : GameConfigSetting;
};

export type PlayerType<T extends GameType> = T extends GameType.Fishbowl
  ? FishbowlPlayer & ActivePlayer
  : T extends GameType.Codenames
  ? CodenamesPlayer
  : Player;

export interface LobbyGame<
  T extends GameType = GameType,
  C = GameConfig<T>,
  P = PlayerType<T>
> extends GenericGame<P> {
  gameType: T;
  config: C;
}
