import { DatabaseReference } from "firebase/database";
import { Database } from "..";
import { FirebaseDatabase } from "../core";
import { CodenamesGame } from "../model/codenames";
import { Reference } from "@firebase/database-types";

export function getCodenamesGameDb(
  getDbRef: () => DatabaseReference | Reference
): Database<CodenamesGame, string> {
  return new FirebaseDatabase<CodenamesGame, string>(getDbRef, "codenameGames");
}
