import { ActiveGame } from "./activeGame";
import { ConfigType, GameConfigSettings, GameType, LobbyPlayer } from "./lobby";

export const defaultFishbowlConfig = {
  /** Number of phrases entered per player, >0 */
  phrasesPerPlayer: 5,
  /** Total number of teams, >0 */
  numberOfTeams: 2,
  /** Number of seconds each player gets per turn, >0 */
  secondsPerTurn: 60,
  /** How many skips per turn without any time penalty, >=0 */
  freeSkips: 1,
  /** Penalty per skip after free skips are used, >=0 */
  skipPenaltySeconds: 10,
  /** Minimum number of contest votes to trigger a penalty, >0 or 0 to disable contesting */
  minContestVotes: 2,
  /** Round names, descriptions and order per game */
  rounds: [
    {
      name: "Taboo",
      description:
        "Use as many words as you want to describe the phrase without saying any words in the phrase. No acting.",
      icon: "speaker_notes",
    },
    {
      name: "Acting",
      description: "Use acting to describe the phrase. No words.",
      icon: "accessibility_new",
    },
    {
      name: "1-Word",
      description:
        "Use only 1 word to describe the phrase. It cannot be a word contained in the phrase. No acting.",
      icon: "looks_one",
    },
  ],
  /** True to show undo guessed button, false hides the button */
  enableUndoGuess: false,
};
export const devDefaultFishbowlConfig = {
  ...defaultFishbowlConfig,
  phrasesPerPlayer: 2,
  enableUndoGuess: true,
  secondsPerTurn: 10,
  numberOfTeams: 1,
};
export type FishbowlConfig = typeof defaultFishbowlConfig;

export const fishbowlConfigSettings: GameConfigSettings<FishbowlConfig> = {
  phrasesPerPlayer: {
    name: "Phrases Per Player",
    tooltip:
      "Number of phrases each player must enter before the host can start the game.",
    configType: ConfigType.Numeric,
    minimum: 1,
  },

  secondsPerTurn: {
    name: "Seconds Per Turn",
    tooltip: "Time taken for each turn of clue giving.",
    configType: ConfigType.Numeric,
    minimum: 1,
  },
  freeSkips: {
    name: "Free Skips Per Turn",
    tooltip:
      "Number of words a clue giver can skip each turn before incurring a time penalty.",
    configType: ConfigType.Numeric,
    minimum: 0,
  },
  skipPenaltySeconds: {
    name: "Skip Penalty in Seconds",
    tooltip:
      "How many seconds a clue giver loses when skipping a word after they are out of free skips.",
    configType: ConfigType.Numeric,
    minimum: 0,
  },
  numberOfTeams: {
    name: "Number of Teams",
    tooltip: "Number of teams to divide players into.",
    configType: ConfigType.Numeric,
    minimum: 1,
  },
  minContestVotes: {
    name: "Minimum Contest Votes",
    tooltip:
      "The minimum number of people that need to contest a guessed word for the point to be removed. 0 removes the option to contest guessed words.",
    configType: ConfigType.Numeric,
    minimum: 0,
  },
  enableUndoGuess: {
    name: "Enable Undo",
    tooltip:
      "Whether or not the clue giver is able to undo the last guessed word.",
    configType: ConfigType.Boolean,
  },
  rounds: {
    name: "Pick Rounds",
    tooltip: "Pick the types of clue giving rounds.",
    configType: ConfigType.Unconfigureable,
  },
};

export interface PhraseData {
  phrase: string;
  count: number;
  passedCount: number;
  guessedCount: number;
  timeTaken: number;
  contestCount: number;
}

export interface FishbowlPlayer extends LobbyPlayer {
  phrases?: { [key: string]: string };
}

export interface GuessedPhrase {
  /** Phrase in its original form */
  phrase: string;
  /** Player IDs that have contested this phrase */
  contests?: string[];
}

export interface FishbowlGame
  extends ActiveGame<GameType.Fishbowl, FishbowlPlayer> {
  /** Index of current round */
  round: number;
  /** Index of team whose turn it is */
  teamTurn: number;
  /** Time the current turn should end; undefined if not giving clues */
  turnEndTime?: number;
  /** Time since current clue was shown */
  currentPhraseStartMs?: number;
  /** Time remaining before pausing in milliseconds */
  pausedRemainingMs?: number;
  /** Phrases not yet guessed in this round */
  phrasePool?: string[];
  /** Phrases skipped during this turn */
  skippedPhrases?: string[];
  /** Number of skips used this turn */
  skipsUsed: number;
  /** Phrases guessed this turn */
  guessedPhrases?: GuessedPhrase[];
  /** User ID of the host */
  host: string;

  /**
   * Current turn number, spanning all players for entire game.
   * Increments when clue giver start giving clues.
   * Used for verifying calls are still in expected state.
   */
  turn: number;
}
