import { randomInt, randomName } from '../../util/random';
import assert from 'assert';
import * as core from '../business/lobby';
import * as messaging from './messaging';
import * as joinableActiveGame from './joinableActiveGame';
import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from '@firebase/auth';
import { validateFishbowlConfig } from '../business/fishbowl/gameSetup';
import db from '../databases';
import {
    Database,
    inTranDefault,
    inTranIfDefined,
    mergeResults,
    Result,
    result,
    success,
    failure,
    JoinableActiveGame,
} from '@playtime/database';
import { ActiveGame } from '@playtime/database/src/model/activeGame';
import { CodenamesGame } from '@playtime/database/src/model/codenames';
import { FishbowlGame } from '@playtime/database/src/model/fishbowl';
import {
    GameType,
    LobbyGame,
    GameAssignmentType,
    GenericGame,
    LobbyGameType,
    GameTypeConfig,
    isFishbowlTypeConfig,
    Users,
} from '@playtime/database/src/model/lobby';
import { getFarAwayColor } from '../business/pure/pure';

function isGameType<T extends GameType>(game: LobbyGame, gameType: T): game is LobbyGame<T> {
    return game.gameType === gameType;
}

export function getGameDb(gameType: GameType): Database<ActiveGame & (FishbowlGame | CodenamesGame), string> {
    if (gameType === GameType.Fishbowl) return db.fishbowlGame;
    if (gameType === GameType.Codenames) return db.codenamesGame;
    throw new Error('no game db for given game type');
}

export function getGameOrLobbyDb(gameType: GameAssignmentType): Database<GenericGame, string> {
    if (gameType === GameType.Fishbowl) return db.fishbowlGame;
    if (gameType === GameType.Codenames) return db.codenamesGame;
    if (gameType === LobbyGameType.Lobby) return db.lobbyGame;
    throw new Error('no game db for given game type');
}

export async function fetchLobbyGameType<T extends GameType>(
    gameId: string,
    gameType: T,
    notExistMessage?: string,
    notGameTypeMessage?: string
): Promise<Result<LobbyGame<T>>> {
    const game = await db.lobbyGame.fetch(gameId);
    if (game === undefined) return failure<LobbyGame<T>>("Fetched game doesn't exist" ?? notExistMessage);
    if (!isGameType(game, gameType)) return failure('Fetched game not expected type' ?? notGameTypeMessage);
    return result(game);
}

export async function assignPlayerToGame(
    gameId: string,
    userId: string,
    gameType: GameAssignmentType = LobbyGameType.Lobby
) {
    return inTranDefault(db.users, userId, (pa) => core.assignPlayerToGame(pa, gameId, gameType), {});
}

export async function createNewGame(
    gameName: string,
    configType: GameTypeConfig,
    userId: string,
    displayName: string
): Promise<Result> {
    const result = isFishbowlTypeConfig(configType) ? validateFishbowlConfig(configType.config) : success();
    if (!result.success) {
        return result;
    }
    const gameId = await db.lobbyGame.create({
        name: gameName,
        ...configType,
        host: userId,
        players: { [userId]: { displayName } },
    });
    return assignPlayerToGame(gameId, userId);
}

export async function joinGame(
    gameId: string,
    userId: string,
    displayName: string,
    gameType?: GameType,
    isActive?: boolean
): Promise<void> {
    if (gameType && isActive) {
        await Promise.all([
            joinableActiveGame.joinActiveGame(gameId, userId, displayName, gameType),
            assignPlayerToGame(gameId, userId, gameType),
        ]);
        return;
    }
    await Promise.all([
        inTranIfDefined(db.lobbyGame, gameId, (game) => {
            game.players = { ...game.players, [userId]: { displayName } };
            return success();
        }),
        assignPlayerToGame(gameId, userId),
    ]);
    await messaging.notifyLobbyHost(gameId, userId, false);
}

export async function deleteAllGames() {
    await Promise.all([
        db.users.deleteAll(),
        db.lobbyGame.deleteAll(),
        db.fishbowlGame.deleteAll(),
        db.codenamesGame.deleteAll(),
    ]);
}

export async function leaveGame(userId: string, gameId: string): Promise<void> {
    const [isEmptyGame] = await Promise.all([
        inTranIfDefined(db.lobbyGame, gameId, (game) => core.removePlayer(game, userId)),
        inTranIfDefined(db.users, userId, (pa) => core.unassignPlayerFromGame(pa, gameId)),
    ]);
    if (isEmptyGame.success && isEmptyGame.value) {
        return await db.lobbyGame.delete(gameId);
    }
    await messaging.notifyLobbyHost(gameId, userId, true);
}

export async function leaveActiveGame(userId: string, gameId: string, gameType: GameType): Promise<void> {
    const gameDb = getGameDb(gameType);
    const [isEmptyGame] = await Promise.all([
        inTranIfDefined(gameDb, gameId, (game) => core.deactivatePlayer(game, userId)),
        inTranIfDefined(db.users, userId, (pa) => core.unassignPlayerFromGame(pa, gameId)),
    ]);
    if (isEmptyGame.success && isEmptyGame.value) {
        await gameDb.delete(gameId);
    }
}

export async function deleteGame(gameId: string): Promise<void> {
    const game = await db.lobbyGame.fetch(gameId);
    if (!game) {
        return;
    }
    await Promise.all(Object.keys(game.players ?? {}).map((userId) => leaveGame(userId, gameId)));
}

export async function deleteActiveGame(gameId: string, gameType: GameType): Promise<Result> {
    const gameDb = getGameDb(gameType);
    const gameToAbort = await gameDb.fetch(gameId);
    if (gameToAbort === undefined) {
        return failure('Game to abort does not exist');
    }

    await Promise.all([
        Object.keys(gameToAbort?.players ?? {}).map((userId) => {
            return inTranIfDefined(db.users, userId, (pa) => {
                return core.unassignPlayerFromGame(pa, gameId);
            });
        }),
        gameDb.delete(gameId),
        db.joinableActiveGames.delete(gameId),
    ]);
    return success();
}

export async function getJoinableGame(gameId: string): Promise<JoinableActiveGame | undefined> {
    const [lobbyGame, joinableActiveGame] = await Promise.all([
        db.lobbyGame.fetch(gameId),
        db.joinableActiveGames.fetch(gameId),
    ]);
    if (lobbyGame !== undefined) return { id: gameId, ...lobbyGame };
    if (joinableActiveGame !== undefined) return joinableActiveGame;
}

export async function activateGame(userId: string, gameId: string) {
    return inTranIfDefined(db.users, userId, (pa) => core.activateGame(pa, gameId));
}

export async function setGameType(users: string[], gameId: string, gameType: GameType) {
    return mergeResults(
        await Promise.all(
            users.map((userId) => inTranIfDefined(db.users, userId, (pa) => core.setGameType(pa, gameId, gameType)))
        )
    );
}

export async function deleteAllSingleUseAccounts(firebaseAuth: Auth) {
    const accounts = await db.accounts.getAll();
    for (const [, account] of Object.entries(accounts)) {
        if (account.isSingleUse) {
            const cred = await signInWithEmailAndPassword(firebaseAuth, account.email, account.password);
            const userAuth = cred.user;
            if (userAuth) {
                await userAuth.delete();
                const user = await db.users.fetch(userAuth.uid);
                const gameId = user?.activeGameId;
                if (gameId) {
                    await leaveGame(userAuth.uid, gameId);
                }
                await db.users.delete(userAuth.uid);
            }
        }
    }
}

export async function createSingleUseAccount(firebaseAuth: Auth, userName: string) {
    const email = `${randomName(5)}@${randomName(8)}.fake`;
    const password = randomName(9);
    const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);
    assert(userCredential.user, 'Expected user for credential');
    await updateProfile(userCredential.user, { displayName: userName });
    await db.accounts.set(userCredential.user.uid, {
        isSingleUse: true,
        email,
        password,
    });
    return userCredential.user;
}

export const allTeamNames: {
    prefix: string;
    primaryColor: [number, number, number];
    secondaryColor: [number, number, number];
    darkFont: boolean;
}[] = [
    { prefix: 'Red', primaryColor: [255, 0, 0], secondaryColor: [171, 39, 39], darkFont: false },
    { prefix: 'Blue', primaryColor: [0, 0, 255], secondaryColor: [19, 19, 138], darkFont: false },
    { prefix: 'Green', primaryColor: [0, 199, 0], secondaryColor: [17, 122, 17], darkFont: false },
    { prefix: 'Yellow', primaryColor: [227, 227, 16], secondaryColor: [214, 193, 34], darkFont: false },
    { prefix: 'Orange', primaryColor: [255, 165, 0], secondaryColor: [214, 114, 0], darkFont: false },
    { prefix: 'Purple', primaryColor: [128, 0, 107], secondaryColor: [61, 0, 122], darkFont: false },
    { prefix: 'Pink', primaryColor: [255, 102, 128], secondaryColor: [217, 67, 149], darkFont: false },
    { prefix: 'Teal', primaryColor: [0, 212, 201], secondaryColor: [21, 175, 189], darkFont: false },
    { prefix: 'Cyan', primaryColor: [0, 255, 242], secondaryColor: [255, 255, 77], darkFont: true },
    { prefix: 'Maroon', primaryColor: [128, 21, 0], secondaryColor: [72, 0, 92], darkFont: false },
    { prefix: 'Burgandy', primaryColor: [166, 5, 5], secondaryColor: [107, 0, 16], darkFont: false },
    { prefix: 'The A', primaryColor: [0, 110, 33], secondaryColor: [110, 68, 0], darkFont: false },
    { prefix: 'Number One', primaryColor: [0, 0, 255], secondaryColor: [184, 255, 218], darkFont: false },
    { prefix: 'Brown', primaryColor: [122, 73, 31], secondaryColor: [94, 30, 22], darkFont: false },
    { prefix: 'Gray', primaryColor: [92, 92, 92], secondaryColor: [91, 120, 118], darkFont: false },
    { prefix: 'Silver', primaryColor: [200, 200, 200], secondaryColor: [150, 150, 150], darkFont: false },
    { prefix: 'Gold', primaryColor: [251, 255, 23], secondaryColor: [196, 154, 0], darkFont: true },
    { prefix: 'Diamond', primaryColor: [219, 254, 255], secondaryColor: [251, 252, 220], darkFont: true },
    { prefix: 'Ruby', primaryColor: [196, 0, 0], secondaryColor: [125, 25, 0], darkFont: false },
    { prefix: 'Sapphire', primaryColor: [13, 0, 255], secondaryColor: [195, 191, 255], darkFont: false },
    { prefix: 'Emerald', primaryColor: [2, 145, 0], secondaryColor: [124, 255, 122], darkFont: false },
    { prefix: 'Onyx', primaryColor: [34, 9, 82], secondaryColor: [66, 6, 6], darkFont: false },
    { prefix: 'Platinum', primaryColor: [153, 153, 153], secondaryColor: [217, 255, 252], darkFont: false },
    { prefix: 'Bronze', primaryColor: [204, 112, 0], secondaryColor: [237, 205, 59], darkFont: false },
    { prefix: 'Topaz', primaryColor: [237, 205, 59], secondaryColor: [38, 255, 248], darkFont: true },
    { prefix: 'Chameleon', primaryColor: [2, 2, 191], secondaryColor: [98, 255, 0], darkFont: false },
    { prefix: 'Raven', primaryColor: [108, 3, 173], secondaryColor: [0, 0, 0], darkFont: false },
    { prefix: 'Boys', primaryColor: [49, 209, 245], secondaryColor: [37, 0, 161], darkFont: false },
    { prefix: 'Girls', primaryColor: [245, 157, 230], secondaryColor: [242, 113, 33], darkFont: false },
    { prefix: 'Big', primaryColor: [255, 187, 0], secondaryColor: [255, 98, 0], darkFont: false },
    { prefix: 'Small', primaryColor: [138, 243, 255], secondaryColor: [254, 217, 255], darkFont: true },
    { prefix: 'Alpha', primaryColor: [201, 224, 255], secondaryColor: [246, 255, 0], darkFont: true },
    { prefix: 'Omega', primaryColor: [181, 133, 0], secondaryColor: [255, 0, 0], darkFont: false },
    { prefix: 'Unicorn', primaryColor: [49, 209, 245], secondaryColor: [255, 0, 212], darkFont: false },
    { prefix: 'Sour Patch', primaryColor: [255, 230, 0], secondaryColor: [166, 255, 0], darkFont: true },
    { prefix: 'Bulldog', primaryColor: [153, 51, 0], secondaryColor: [166, 166, 166], darkFont: false },
    { prefix: 'Indigo', primaryColor: [75, 0, 130], secondaryColor: [75, 0, 130], darkFont: false },
];
const allTeamSuffixes = [
    'Team',
    'Squad',
    'Group',
    'Unit',
    'Formation',
    'Collective',
    'LLC',
    'Army',
    'Crew',
    'Gang',
    'Band',
    'Club',
    'Community',
];

export function generateTeams(count: number) {
    assert(count > 0, 'count should be positive');
    const seedTeam = allTeamNames[randomInt(allTeamNames.length)];
    const teams = [seedTeam];
    for (let i = 0; i < count - 1; i++)
        teams.push(
            getFarAwayColor(
                allTeamNames,
                teams.map((x) => x.primaryColor)
            )
        );
    return teams.map((t) => ({
        name: `${t.prefix} ${allTeamSuffixes[randomInt(allTeamSuffixes.length)]}`,
        primaryColor: t.primaryColor,
        secondaryColor: t.secondaryColor,
        darkFont: t.darkFont,
    }));
}

export async function getUsers(userIds: string[]): Promise<Result<Users>> {
    const userResponses = await Promise.all(
        userIds.map(async (userId) => {
            const user = await db.users.fetch(userId);
            return [userId, user];
        })
    );
    const nonExistentUsers = userResponses.filter(([_, user]) => user === undefined);
    if (nonExistentUsers.length > 0) return fail(`these user ids don't exist: ${nonExistentUsers.join(', ')}`);
    return result(Object.fromEntries(userResponses));
}
