import React from 'react';
import { Container, Avatar, Typography, Box, Button } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Copyright from '../Authentication/Copyright/Copyright';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ThirdPartySignIn from '../Authentication/ThirdPartySignIn/ThirdPartySignIn';
import EmailSignIn from '../Authentication/EmailSignIn/EmailSignIn';
import CreateAccount from '../Authentication/CreateAccount/CreateAccount';
import { setUser } from '../../store/auth/actions';
import { HOME_ROUTE, SINGLESIGNON_ROUTE } from '../router/routing';
import {
    AuthProvider,
    FacebookAuthProvider,
    GoogleAuthProvider,
    signInWithPopup,
    TwitterAuthProvider,
} from 'firebase/auth';
import { useSelector } from 'react-redux';
import { getFirebaseAuth } from '../../store/auth/selectors';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
}));

const Signin: React.FunctionComponent = () => {
    const [isCreateAccount, setIsCreateAccount] = useState(false);
    const firebaseAuth = useSelector(getFirebaseAuth);
    const { classes } = useStyles();
    const history = useHistory();
    const location = useLocation<{ from: string }>();
    const { from } = location.state || { from: { pathname: HOME_ROUTE } };
    if (!firebaseAuth) return null;
    const redirectBack = () => {
        history.replace(from);
    };

    const thirdPartySignIn = (provider: AuthProvider) => {
        signInWithPopup(firebaseAuth, provider)
            .then(function (result) {
                if (result.user) {
                    setUser(result.user);
                    redirectBack();
                }
            })
            .catch(function (error) {
                alert(error.message);
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {isCreateAccount ? 'Create Account' : 'Sign In'}
                </Typography>
                <ThirdPartySignIn
                    onClick={() => thirdPartySignIn(new GoogleAuthProvider())}
                    logoImagePath="/google.png"
                    logoName="Google"
                />
                <ThirdPartySignIn
                    onClick={() => thirdPartySignIn(new FacebookAuthProvider())}
                    logoImagePath="/facebook.png"
                    logoName="Facebook"
                />
                <ThirdPartySignIn
                    onClick={() => thirdPartySignIn(new TwitterAuthProvider())}
                    logoImagePath="/twitter.png"
                    logoName="Twitter"
                />
                <Button variant="outlined" onClick={() => history.push(SINGLESIGNON_ROUTE, { from: from })}>
                    Single-Use Sign In
                </Button>
                {isCreateAccount ? (
                    <CreateAccount onCreateAccount={redirectBack} onSignIn={() => setIsCreateAccount(false)} />
                ) : (
                    <EmailSignIn onCreateAccount={() => setIsCreateAccount(true)} onSignIn={redirectBack} />
                )}
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
};

export default Signin;
