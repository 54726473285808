import { success } from '@playtime/database';
import { PlayerStats } from '@playtime/database/src/model/stats';

export function streaked(
    playerStats: Required<PlayerStats>,
    isClueGiver: boolean,
    combo: string[],
    streakEnder: 'victory' | 'pass' | 'assassin' | 'bystandard' | 'opposingTeam'
) {
    if (isClueGiver) {
        playerStats.codenamesStats.streaks = playerStats.codenamesStats.streaks ?? {};
        playerStats.codenamesStats.streaks[combo.length] = playerStats.codenamesStats.streaks[combo.length]
            ? playerStats.codenamesStats.streaks[combo.length]
            : { count: 0, words: [] };
        playerStats.codenamesStats.streaks[combo.length].count++;
        playerStats.codenamesStats.streaks[combo.length].words?.push(combo);
        if (streakEnder === 'assassin') playerStats.codenamesStats.assassinCount++;
        else if (streakEnder === 'bystandard') playerStats.codenamesStats.bystandardCount++;
        else if (streakEnder === 'opposingTeam') playerStats.codenamesStats.enemyAgentCount++;
        return success();
    }
    if (streakEnder === 'assassin') playerStats.codenamesStats.assassinGuesserCount++;
    else if (streakEnder === 'bystandard') playerStats.codenamesStats.bystandardGuesserCount++;
    else if (streakEnder === 'opposingTeam') playerStats.codenamesStats.enemyAgentGuesserCount++;
    else if (combo.length > 0) playerStats.codenamesStats.allyAgentGuesserCount++;
    return success();
}
