import * as types from './types';
import store from '..';
import { Auth, User } from 'firebase/auth';

export const setUser = (user: User): void => {
    window.localStorage.setItem('logged-in', 'true');
    store.store.dispatch({
        type: types.SET_USER,
        user,
    });
};

export const logout = (): void => {
    window.localStorage.setItem('logged-in', 'false');
    store.store.dispatch({
        type: types.LOGOUT,
        user: null,
    });
};

export const setFirebaseAuth = (firebaseAuth: Auth) => {
    store.store.dispatch({
        type: types.SET_FIREBASE_AUTH,
        firebaseAuth,
    });
};
