import { Badge, Box, Card, Typography } from '@mui/material';
import { CodenamesGame, CodenamesTeam } from '@playtime/database/src/model/codenames';
import { FishbowlGame } from '@playtime/database/src/model/fishbowl';
import { Team as ITeam } from '@playtime/database/src/model/activeGame';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getTeamForPlayer } from '../../../back-end';
import { activateGame } from '../../../back-end/api/lobby';
import { getUser } from '../../../store/auth/selectors';
import { isNullOrUndefined } from '../../../util/util';
import { SyncedComponentProps } from '../../hoc/sync';
import { getGameRoute } from '../../router/routing';
import Team from './Team';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    gameName: {
        textAlign: 'center',
        minWidth: 0,
    },
    container: {
        margin: 10,
        padding: 6,
        width: '100%',
        cursor: 'pointer',
        webkitTouchCallout: 'none',
        webkitUserSelect: 'none',
        khtmlUserSelect: 'none',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
    myTurnBadge: {
        width: '100%',
    },
    myTurnBadgeAnchor: {
        top: 9,
        right: 20,
    },
});

interface MyGameProps
    extends SyncedComponentProps<
            Pick<CodenamesGame | FishbowlGame, 'name' | 'teams' | 'players' | 'gameType' | 'teamTurn'>
        >,
        SyncedComponentProps<Pick<CodenamesGame, 'clueGiven' | 'words'>> {
    gameId: string;
}

const MyGame: React.FunctionComponent<MyGameProps> = (props) => {
    const { classes } = useStyles();
    const user = useSelector(getUser);
    const route = useHistory();
    if (!props.teams?.length || !props.players) return null;
    const { team: myTeam, index: myTeamIndex } = getTeamForPlayer(props.teams, user.uid);
    const goToGame = async () => {
        await activateGame(user.uid, props.gameId);
        if (isNullOrUndefined(props.gameType)) return;
        route.push(getGameRoute(props.gameType));
    };
    const getPlayersTurn = (): string[] => {
        if (!props.teams || isNullOrUndefined(props.teamTurn)) return [];
        const activeTeam = props.teams[props.teamTurn];
        const activePlayer = activeTeam.players[activeTeam.playerTurn];

        // TODO: sloppy business logic should be refactored for
        // better readability instead of comments

        // if the 2 codenames props ('words', 'clueGiven') are undefined, it's fishbowl
        if (isNullOrUndefined(props.words) && isNullOrUndefined(props.clueGiven)) return [activePlayer];

        // if either 'words' or 'clueGiven' are defined, we know it's not fishbowl.
        // no 'words' indicate the game is waiting for clue givers to be ready
        if (!props.words) {
            return (props.teams as CodenamesTeam[]).reduce<string[]>((playersTurn, team) => {
                if (!team.isReady) playersTurn.push(team.players[team.playerTurn]);
                return playersTurn;
            }, []);
        }

        // at this point, props.words is defined and so it's either the clue givers turn
        // (activePlayer) or clue guessers turn
        return !props.clueGiven ? [activePlayer] : activeTeam.players.filter((p) => p !== activePlayer);
    };
    const playersTurn = getPlayersTurn();

    const BadgeIfTurn: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
        const isMyTurn = playersTurn.includes(user.uid);
        return isMyTurn ? (
            <Badge
                overlap="rectangular"
                classes={{ root: classes.myTurnBadge, anchorOriginTopRightRectangular: classes.myTurnBadgeAnchor }}
                color="secondary"
                badgeContent="My Turn"
            >
                {children}
            </Badge>
        ) : (
            <Box width="100%" display="inline-flex">
                {children}
            </Box>
        );
    };
    return (
        <BadgeIfTurn>
            <Card elevation={3} className={classes.container} onClick={goToGame}>
                <Typography variant="h5" className={classes.gameName}>
                    {props.name}
                </Typography>
                <Box display="inline-flex" flexDirection="row" width="100%">
                    <Box flex={1}>
                        {myTeam && (
                            <Team team={myTeam} players={props.players} playersTurn={playersTurn} align="right" />
                        )}
                    </Box>
                    {props.teams.length > 1 && (
                        <Box mx={1} marginTop="4px">
                            vs
                        </Box>
                    )}
                    <Box flex={1} flexWrap="nowrap">
                        {(props.teams as ITeam[]).map((team, i) => {
                            if (!props.players || i === myTeamIndex) return;
                            return (
                                <Team
                                    key={team.name}
                                    team={team}
                                    players={props.players}
                                    playersTurn={playersTurn}
                                    align="left"
                                />
                            );
                        })}
                    </Box>
                </Box>
            </Card>
        </BadgeIfTurn>
    );
};

export default MyGame;
