import { DatabaseReference } from "firebase/database";
import { Database } from "..";
import { FirebaseDatabase } from "../core";
import { Account } from "../model/lobby";
import { Reference } from "@firebase/database-types";

export function getAccountsDb(
  getDbRef: () => DatabaseReference | Reference
): Database<Account, string> {
  return new FirebaseDatabase<Account, string>(getDbRef, "accounts");
}
