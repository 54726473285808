import { DatabaseReference } from "firebase/database";
import { Database } from "..";
import { FirebaseDatabase } from "../core";
import { PlayerStats } from "../model/stats";
import { Reference } from "@firebase/database-types";

export function getPlayerStatsDb(
  getDbRef: () => DatabaseReference | Reference
): Database<PlayerStats, string> {
  return new FirebaseDatabase<PlayerStats, string>(getDbRef, "playerStats");
}
