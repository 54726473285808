import { Word, CodenamesTeam, SpyType } from '@playtime/database/src/model/codenames';

export function getColor(
    word: Word,
    teams: CodenamesTeam[]
): { darkFont?: boolean; primaryColor: [number, number, number]; secondaryColor?: [number, number, number] } {
    switch (word.spyType) {
        case SpyType.Assassin:
            return { primaryColor: [0, 0, 0] };
        case SpyType.Bystandard:
            return { primaryColor: [220, 213, 185], darkFont: true };
    }
    const teamIx = word.spyType - SpyType.TeamStart;
    const team = teams[teamIx];
    return { primaryColor: team.primaryColor, secondaryColor: team.secondaryColor, darkFont: team.darkFont };
}
