import React, { useEffect, useState } from 'react';
import { Lobby } from '../../Lobby/Lobby';
import { BaseGameProps } from '../../models/game';
import { BaseLobbyProps } from '../../Lobby/baseLobbyProps';
import { useSelector } from 'react-redux';
import { getGame } from '../../../store/game/selectors';
import { useHistory } from 'react-router-dom';
import { Handles } from '../../../util/handles';
import { HOME_ROUTE } from '../../router/routing';
import db from '../../../back-end/databases';
import { Database } from '@playtime/database';
import { CodenamesGame } from '@playtime/database/src/model/codenames';
import { FishbowlGame } from '@playtime/database/src/model/fishbowl';
import { LobbyGame } from '@playtime/database/src/model/lobby';

export interface PlayersGameProps {
    InGame: React.FunctionComponent<BaseGameProps>;
    GameSpecificLobby: React.FunctionComponent<BaseLobbyProps>;
    activeGameDb: Database<FishbowlGame | CodenamesGame, string, Record<string, unknown>>;
}

function useExistenceState<T>() {
    const [state, setState] = useState<T | undefined>(undefined);
    const setStateIfExistChanged = (s: T | undefined) => {
        if ((state === undefined && s !== undefined) || (state !== undefined && s == undefined)) {
            setState(s);
        }
    };
    return [state, setStateIfExistChanged] as const;
}

/** Rerenders only when user joins/leaves a game or a game starts/ends. */
export const Game: React.FunctionComponent<PlayersGameProps> = (props) => {
    const game = useSelector(getGame);
    const route = useHistory();
    const [initialLobbyState, setInitialLobbyState] = useExistenceState<LobbyGame>();
    const [initialGameState, setInitialGameState] = useExistenceState<BaseGameProps['initialGameState']>();

    useEffect(() => {
        if (!game?.id) return route.push(HOME_ROUTE);
        return Handles.join(
            db.lobbyGame.listen(game.id, setInitialLobbyState),
            props.activeGameDb.listen(game.id, setInitialGameState)
        ).stop;
    }, [game?.id, initialLobbyState, initialGameState]);

    const InGame = props.InGame;

    if (!game?.id || !game.game) {
        return <div>Loading...</div>;
    }
    if (initialLobbyState) {
        return (
            <Lobby GameSpecificLobby={props.GameSpecificLobby} gameId={game.id} initialGameState={initialLobbyState} />
        );
    }
    if (initialGameState) {
        return <InGame gameId={game.id} initialGameState={initialGameState} />;
    }
    return null;
};
