import { Result, result, success, failure } from '@playtime/database';
import { CodenamesConfig, CodenamesGame, SpyType, CodenamesTeam } from '@playtime/database/src/model/codenames';
import { cyclicIndex, push, shuffle } from '../../../util/util';

export function getNewWordSet(
    words: string[],
    config: Pick<CodenamesConfig, 'numberOfClues' | 'numberOfAssassins' | 'numberOfWords'>,
    teams: CodenamesGame['teams']
): Result<CodenamesGame['words']> {
    if (words.length !== config.numberOfWords)
        return failure("codenames word count didn't equal the configured number of words");
    if (config.numberOfWords < config.numberOfClues * teams.length + config.numberOfAssassins)
        return failure('invalid codenames config. too many special cards');
    const spyTypes: SpyType[] = [];
    // + 1 because the first team has one more clue to guess to balance the game;
    const numberOfTeamWords = config.numberOfClues * teams.length + 1;
    push(spyTypes, SpyType.Bystandard, config.numberOfWords - numberOfTeamWords - config.numberOfAssassins);
    push(spyTypes, SpyType.Assassin, config.numberOfAssassins);
    for (let i = 0; i < teams.length; i++) {
        // the team that starts clue giving has 1 more clue to guess
        push(spyTypes, SpyType.TeamStart + i, i === 0 ? config.numberOfClues + 1 : config.numberOfClues);
    }
    shuffle(spyTypes);
    return result(
        Object.fromEntries(
            words.map((val, ix) => [
                val,
                {
                    word: val,
                    spyType: spyTypes[ix],
                    revealed: false,
                },
            ])
        )
    );
}

export function getNextTurn(team: CodenamesTeam) {
    return cyclicIndex(team.players, team.playerTurn + 1);
}

export function removeWordVotes(game: CodenamesGame) {
    Object.keys(game.words).forEach((w) => delete game.words[w].voters);
}

export function passTeam(game: CodenamesGame) {
    game.teamTurn = cyclicIndex(game.teams, game.teamTurn + 1);
    removeWordVotes(game);
}

export function getClueGiver(game: CodenamesGame) {
    const clueGivingTeam = game.teams[game.teamTurn];
    return clueGivingTeam.players[clueGivingTeam.playerTurn];
}

export function getClueGivers(game: CodenamesGame): string[] {
    return game.teams.map((team) => team.players[team.playerTurn]);
}

export function endStreak(game: CodenamesGame): {
    streakEnded: true;
    streak: string[];
    clueGiver: string;
    players: readonly string[];
    config: CodenamesConfig;
} {
    const streak = [...(game.streak ?? [])];
    game.streak = [];
    game.clueGiven = false;
    const clueGiver = getClueGiver(game);
    const players = game.teams[game.teamTurn].players;
    passTeam(game);
    game.turn++;
    return { streakEnded: true, streak, clueGiver, players, config: game.config };
}

export function resetGame(game: CodenamesGame) {
    game.words = {};
    game.gameLog = [];
    game.teams.forEach((t) => {
        t.playerTurn = getNextTurn(t);
        t.isReady = false;
        t.scores[0] = 0;
    });
    const lastTeam = game.teams.pop();
    if (!lastTeam) return failure('no teams are in the game');
    game.teams.unshift(lastTeam);
    game.teamTurn = 0;
    return success();
}
