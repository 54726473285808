import { DatabaseReference } from "firebase/database";
import { Database } from "..";
import { FirebaseDatabase } from "../core";
import { PhraseData } from "../model/fishbowl";
import { Reference } from "@firebase/database-types";

export function getPhraseCollectionDb(
  getDbRef: () => DatabaseReference | Reference
): Database<PhraseData, string> {
  return new FirebaseDatabase<PhraseData, string>(getDbRef, "phraseCollection");
}
