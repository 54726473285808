import { Team, ActiveGame } from '@playtime/database/src/model/activeGame';
import { CodenamesGame, Clue, isClue } from '@playtime/database/src/model/codenames';
import { FishbowlGame, FishbowlPlayer, FishbowlConfig } from '@playtime/database/src/model/fishbowl';
import { Outcome } from '@playtime/database/src/model/historicalGames';
import assert from 'assert';
import { allTeamNames } from '../..';
import { consolidateSpaces, deepClone, escapeFirebaseKey, getAverage, Immutable } from '../../../util/util';
import { PlayerOutcome } from '../../api/playerStats';

export function getCurrentPhrase(phrasePool: FishbowlGame['phrasePool']): string | undefined {
    return phrasePool?.slice(-1)[0];
}

export function getTeamForPlayer<T extends Team>(teams: T[], userId: string): { index: number; team?: T } {
    const index = teams.findIndex((team) => team.players.some((id) => id === userId));
    const team = index < 0 ? undefined : teams[index];
    return { index, team };
}

export function isPlayersTurn<T extends Team>(team: T, userId: string): boolean {
    return team.playerTurn === team.players.indexOf(userId);
}

export function countRemainingPhrases(game: Pick<FishbowlGame, 'phrasePool' | 'skippedPhrases'>) {
    return (game.phrasePool ?? []).length + (game.skippedPhrases ?? []).length;
}

export function countTotalPhrases(players: Record<string, FishbowlPlayer>): number {
    const values = Object.values(players ?? {});
    return values.reduce((p, c) => p + Object.keys(c.phrases ?? {}).length, 0);
}

export function getAllPhrases(players: Record<string, FishbowlPlayer>): string[] {
    const values = Object.values(players ?? {});
    return values.reduce<string[]>((p, c) => p.concat(Object.values(c.phrases ?? {})), []);
}

export function convertPhraseToKey(phrase: string): string {
    // Trim leading and trailing whitespace, transform to lowercase, and replace any
    // series of consecutive spaces with a single space.
    const unescaped = consolidateSpaces(phrase).toLowerCase();

    return escapeFirebaseKey(unescaped);
}

export function makeMutableGameConfigCopy(config: Immutable<FishbowlConfig>): FishbowlConfig {
    return deepClone<FishbowlConfig>(config);
}

export function hexToRgb(hex: string): [number, number, number] | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

export interface RGBColorScheme {
    primary: string;
    secondary: string;
    intermediate: string;
}

export const getColorSchemeRgb = (
    primaryColor?: [number, number, number],
    secondaryColor?: [number, number, number]
): RGBColorScheme => ({
    primary: primaryColor ? primaryColor.join(',') : '200,200,200',
    secondary: secondaryColor ? secondaryColor.join(',') : '150,150,150',
    intermediate: primaryColor && secondaryColor ? getAverage(primaryColor, secondaryColor).join(',') : '175,175,175',
});

export function getFarAwayColor(
    colorConfig: typeof allTeamNames,
    originColors: [number, number, number][]
): typeof allTeamNames[number] {
    const sortedDeviation = colorConfig.sort((a, b) => {
        let deviationA = 765;
        let deviationB = 765;
        for (const originColor of originColors) {
            deviationA = Math.min(
                deviationA,
                Math.abs(a.primaryColor[0] - originColor[0]) +
                    Math.abs(a.primaryColor[1] - originColor[1]) +
                    Math.abs(a.primaryColor[2] - originColor[2])
            );
            deviationB = Math.min(
                deviationB,
                Math.abs(b.primaryColor[0] - originColor[0]) +
                    Math.abs(b.primaryColor[1] - originColor[1]) +
                    Math.abs(b.primaryColor[2] - originColor[2])
            );
        }
        return deviationA - deviationB > 0 ? 1 : -1;
    });
    const halfWay = colorConfig.length / 2;
    const randomIndexAtLeastHalfway = Math.floor(halfWay) + Math.floor(Math.random() * halfWay);
    return sortedDeviation[randomIndexAtLeastHalfway];
}

export function getCurrentClueGiver(game: FishbowlGame) {
    const currentTeam = game.teams[game.teamTurn];
    const id = currentTeam.players[currentTeam.playerTurn];
    const name = game.players[id];
    return { id, name };
}

export function trueNow(offset: number, now?: number) {
    return (now ?? Date.now()) - offset;
}

export function secondsDiff(ms1: number, ms2: number) {
    return Math.round(Math.abs(ms1 - ms2) / 1000);
}

/** Returns array of highest scoring teams' index */
export function getWinningTeamIndices(teams: Pick<Team, 'scores'>[]) {
    assert(teams.length, 'there needs to be at least 1 team to be a winner');
    if (teams.length === 1) return [0];
    const totals = teams.map((team) => team.scores.reduce((amount, roundScore) => (amount += roundScore)), 0);
    let winningIndices = [0];
    let max = totals[0];
    for (let i = 1; i < teams.length; i++) {
        if (totals[i] > max) {
            max = totals[i];
            winningIndices = [i];
        } else if (totals[i] === max) winningIndices.push(i);
    }
    return winningIndices;
}

/** Returns array of players and their game outcome (win, loss or tie) */
export function getPlayersOutcome(teams: Pick<Team, 'players'>[], winningIndices: number[]): PlayerOutcome[] {
    const playersOutcome: PlayerOutcome[] = [];
    for (let i = 0; i < teams.length; i++) {
        let outcome: Outcome = 'loss';
        if (winningIndices.includes(i)) {
            outcome = winningIndices.length > 1 ? 'tie' : 'win';
        }
        for (const player of teams[i].players) {
            playersOutcome.push({ id: player, outcome });
        }
    }
    return playersOutcome;
}

export function getTeamOutcome(winningTeamIndices: number[], ix: number) {
    const isInWinning = winningTeamIndices.indexOf(ix) !== -1;
    return isInWinning ? (winningTeamIndices.length > 1 ? 'tie' : 'win') : 'loss';
}

export function getPlayerIds(game: Pick<ActiveGame, 'teams'>) {
    return game.teams.reduce<string[]>((allPlayers: string[], team) => allPlayers.concat(team.players), []);
}

/** team size is the size of the biggest team. dummy players will act as a placeholder for balancing purposes */
export function getTeamSize(numPlayers: number, numTeams: number) {
    assert(numPlayers > 0 && numTeams > 0, 'number of players and teams should be positive');
    assert(numPlayers >= numTeams, 'number of players should be greater than or equal to the number of teams');
    return Math.ceil(numPlayers / numTeams);
}

export function findObject<K extends string | number | symbol, T>(
    objects: Record<K, T>,
    condition: (object: [K, T]) => boolean
) {
    for (const entry of Object.entries(objects)) {
        if (condition(entry as [K, T])) return entry as [K, T];
    }
    return null;
}

export function getLastClue(gameLog: CodenamesGame['gameLog']): Clue | undefined {
    return gameLog?.find((log) => isClue(log)) as Clue;
}

export function getClueCount(clue: Clue) {
    if (clue.count === '0' || clue.count === '∞') return -1;
    return Number(clue.count);
}
