import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Route, Link, Switch, useHistory } from 'react-router-dom';
import Home from '../pages/Home';
import SignIn from '../pages/SignIn';
import SingleSignOn from '../pages/SingleSignOn';
import { Fishbowl } from '../pages/Games/Fishbowl';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Tooltip,
    Switch as MuiSwitch,
    Divider,
    Container,
    Button,
    Box,
} from '@mui/material';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import StorageIcon from '@mui/icons-material/Storage';
import AccountCircle from '@mui/icons-material/AccountCircle';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { isFirebaseLoaded } from '../../store/appSettings/selectors';
import PrivateRoute from '../router/PrivateRoute';
import { checkForUser } from '../../store/auth/selectors';
import { useSelector } from 'react-redux';
import { Management } from '../pages/Management';
import SettingsIcon from '@mui/icons-material/Settings';
import { activateGame, leaveActiveGame, leaveGame } from '../../back-end';
import Stats from '../pages/Stats';
import Codenames from '../pages/Games/Codenames';
import { CODENAMES_ROUTE, FISHBOWL_ROUTE, HOME_ROUTE, MANAGEMENT_ROUTE, STATS_ROUTE } from '../router/routing';
import { setGame } from '../../store/game/actions';
import { Immutable } from '../../util/util';
import { getGame } from '../../store/game/selectors';
import { SystemMode } from '../../setup/mode';
import { setSystemAppMode, setSystemDbMode } from '../../store/devTools/actions';
import { getSystemAppMode, getSystemDbMode } from '../../store/devTools/selectors';
import { getMessaging, MessagePayload, onMessage } from '@firebase/messaging';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';
import store from '../../store';
import { Close } from '@mui/icons-material';
import Logout from './MenuItems/Logout';
import IconDialog from '../common/IconDialog';
import assert from 'assert';
import db from '../../back-end/databases';
import { LobbyGame } from '@playtime/database/src/model/lobby';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        cursor: 'pointer',
        webkitTouchCallout: 'none',
        webkitUserSelect: 'none',
        khtmlUserSelect: 'none',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    link: {
        textDecoration: 'inherit',
        color: 'inherit',
    },
    content: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '15px',
        height: 'calc(100% - 71px)',
    },
    tooltip: {
        whiteSpace: 'pre-line',
    },
}));

export default function Layout() {
    const isDev = (mode: SystemMode) => mode === 'dev';
    const getMode = (checked: boolean) => (checked ? 'dev' : 'prod');
    const { classes } = useStyles();
    const route = useHistory();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [curLocation, setCurLocation] = useState(window.location.pathname);
    const [copyGameLinkTooltipOpen, setCopyGameLinkTooltipOpen] = useState(false);
    const [copyGameLink, setCopyGameLink] = useState('');
    const [showLeaveGameConfirmation, setShowLeaveGameConfirmation] = useState(false);

    const _isFirebaseLoaded = useSelector(isFirebaseLoaded);
    const user = useSelector(checkForUser);
    const game = useSelector(getGame);
    const systemAppMode = useSelector(getSystemAppMode);
    const systemDbMode = useSelector(getSystemDbMode);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const toggleAppModeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSystemAppMode(getMode(e.target.checked), true);
    };
    const toggleDbModeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSystemDbMode(getMode(e.target.checked), true);
    };

    useEffect(() => {
        if (user !== null && _isFirebaseLoaded) {
            // TODO: not sure if i like adding this here, let a lone to redux
            return db.users.listen(user.uid, async (pa) => {
                let game: Partial<Immutable<LobbyGame>> | undefined;
                if (pa?.activeGameId) {
                    game = (
                        await Promise.all([
                            db.lobbyGame.fetch(pa.activeGameId),
                            db.fishbowlGame.fetch(pa.activeGameId),
                            db.codenamesGame.fetch(pa.activeGameId),
                        ])
                    ).reduce<Partial<Immutable<LobbyGame>> | undefined>(
                        (prev, item) => (prev = prev ?? item),
                        undefined
                    );
                }
                setGame(pa?.activeGameId, game, pa?.games);
                setCopyGameLink(`${window.location.origin}/join-${pa?.activeGameId}`);
            }).stop;
        }
        return undefined;
    }, [user?.uid, _isFirebaseLoaded, systemDbMode]);

    useEffect(
        () =>
            route.listen((listener) => {
                setCurLocation(listener.pathname);
            }),
        []
    );
    const isInGame = game?.id && [CODENAMES_ROUTE, FISHBOWL_ROUTE].includes(curLocation);
    const isInCodenamesGame = game?.id && [CODENAMES_ROUTE].includes(curLocation);

    const goToTurnbaseGame = async (gameId: string, snackbarKey: SnackbarKey) => {
        if (!user?.uid) return;
        await activateGame(user.uid, gameId);
        route.push(CODENAMES_ROUTE);
        closeSnackbar(snackbarKey);
    };

    function dismissNotification(key: SnackbarKey) {
        return (
            <IconButton onClick={() => closeSnackbar(key)} size="large">
                <Close />
            </IconButton>
        );
    }
    const goToTurnbaseGameButton: (notification: MessagePayload) => SnackbarAction = (notification) =>
        function goToGame(key) {
            return (
                <Box display="flex">
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => notification.data?.gameId && goToTurnbaseGame(notification.data.gameId, key)}
                    >
                        <span style={{ color: 'white' }}>Go to game</span>
                    </Button>
                    <IconButton onClick={() => closeSnackbar(key)} size="large">
                        <Close />
                    </IconButton>
                </Box>
            );
        };
    React.useEffect(() => {
        const setupNotifications = async () => {
            if (!_isFirebaseLoaded) return;
            const firebaseApp = store.store.getState().appSettings.firebaseApp;
            if (!firebaseApp) return;
            const messaging = getMessaging(firebaseApp);
            onMessage(messaging, (notification) => {
                const isOnGamePage =
                    notification.data?.gameId === game?.id && window.location.pathname === CODENAMES_ROUTE;
                enqueueSnackbar(`${notification.data?.title} ${notification.data?.body}`, {
                    autoHideDuration: null,
                    variant: 'info',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    action: isOnGamePage ? dismissNotification : goToTurnbaseGameButton(notification),
                });
            });
        };

        setupNotifications();
    }, [_isFirebaseLoaded]);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setDrawerOpen(open);
    };
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const goToStats = () => {
        route.push(STATS_ROUTE);
    };
    const goHome = () => {
        route.push(HOME_ROUTE);
    };

    const closeCopyLinkTooltip = () => {
        setCopyGameLinkTooltipOpen(true);
        setTimeout(() => setCopyGameLinkTooltipOpen(false), 1000);
    };

    const getCopyGameLinkButton = () => {
        return (
            <CopyToClipboard text={copyGameLink} onCopy={closeCopyLinkTooltip}>
                <Tooltip title="Link copied!" open={copyGameLinkTooltipOpen} placement="bottom" arrow>
                    <IconButton
                        aria-label="copy link to game"
                        aria-controls="menu-appbar"
                        color="inherit"
                        edge="start"
                        size="large"
                    >
                        <FileCopyIcon />
                    </IconButton>
                </Tooltip>
            </CopyToClipboard>
        );
    };

    const confirmLeaveGame = () => {
        assert(
            user?.uid && game?.id && game?.game?.gameType !== undefined,
            "user doesn't exist or doesn't belong to a game to leave"
        );
        leaveGame(user.uid, game.id);
        leaveActiveGame(user.uid, game.id, game.game.gameType);
    };

    return (
        <Switch>
            <Route path="/signin" render={() => <SignIn />} />
            <Route exact path="/single-sign-on">
                <SingleSignOn />
            </Route>
            <PrivateRoute path="/">
                <AppBar position="static" color={isDev(systemDbMode) ? 'default' : 'secondary'}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            size="large"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                            <div
                                className={classes.list}
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                            >
                                <List>
                                    <Link to="/" className={classes.link}>
                                        <ListItem button key="Home">
                                            <ListItemIcon>
                                                <HomeIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Home" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/management" className={classes.link}>
                                        <ListItem button key="Management">
                                            <ListItemIcon>
                                                <SettingsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Management" />
                                        </ListItem>
                                    </Link>
                                </List>
                            </div>
                            <div>
                                <List>
                                    <Divider variant="inset" component="li" />
                                    <ListItem>
                                        <ListItemIcon>
                                            <DeveloperModeIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="App dev mode" />
                                        <ListItemSecondaryAction>
                                            <MuiSwitch
                                                edge="end"
                                                checked={isDev(systemAppMode)}
                                                onChange={toggleAppModeCheck}
                                            ></MuiSwitch>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <StorageIcon></StorageIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Use dev DB" />
                                        <ListItemSecondaryAction>
                                            <MuiSwitch
                                                edge="end"
                                                checked={isDev(systemDbMode)}
                                                onChange={toggleDbModeCheck}
                                            ></MuiSwitch>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </div>
                        </Drawer>
                        <Typography variant="h6" className={classes.title} onClick={() => goHome()}>
                            PlayTime
                        </Typography>

                        {user && (
                            <div>
                                {isInGame ? <>{getCopyGameLinkButton()}</> : null}
                                {isInCodenamesGame && (
                                    <IconDialog
                                        iconName="exit_to_app"
                                        showDialog={showLeaveGameConfirmation}
                                        setShowDialog={setShowLeaveGameConfirmation}
                                        confirmText="Yes"
                                        declineText="No"
                                        confirm={confirmLeaveGame}
                                    >
                                        Are you sure you want to leave this game?
                                    </IconDialog>
                                )}
                                {user.displayName}
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                    size="large"
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={goToStats}>Stats</MenuItem>
                                    <Logout onClick={handleClose} />
                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
                {!_isFirebaseLoaded ? (
                    <div>Loading...</div>
                ) : (
                    <Container maxWidth="md" className={classes.content}>
                        <Switch>
                            <PrivateRoute exact path={FISHBOWL_ROUTE}>
                                <Fishbowl />
                            </PrivateRoute>
                            <PrivateRoute exact path={[CODENAMES_ROUTE, `${CODENAMES_ROUTE}/:id`]}>
                                <Codenames />
                            </PrivateRoute>
                            <PrivateRoute exact path={STATS_ROUTE}>
                                <Stats />
                            </PrivateRoute>
                            <PrivateRoute exact path={MANAGEMENT_ROUTE}>
                                <Management />
                            </PrivateRoute>
                            <Route exact path={['/join-:id', HOME_ROUTE]}>
                                <Home />
                            </Route>
                        </Switch>
                    </Container>
                )}
            </PrivateRoute>
        </Switch>
    );
}
