import { result } from '@playtime/database';
import { ActiveGame } from '@playtime/database/src/model/activeGame';
import { HistoricalTeam, HistoricalGame } from '@playtime/database/src/model/historicalGames';
import { GameType } from '@playtime/database/src/model/lobby';
import { getTeamOutcome, getTeamSize } from '..';
import { PLACEHOLDER_ID_PREFIX } from '../api/fishbowl/fishbowlStats';
import { getEloChanges } from './pure/elo';

export function getHistoricalGameRecord(
    gameToRecord: Pick<ActiveGame, 'teams'>,
    playersCurrentElo: Record<string, number>,
    winningTeamIndices: number[],
    placeholderElo: { id: string; elo: number } | null,
    gameType: GameType
) {
    const teamSize = getTeamSize(Object.keys(playersCurrentElo).length, gameToRecord.teams.length);
    const teamsWithoutEloChangeWithPlaceholder: Omit<HistoricalTeam, 'eloChange'>[] = gameToRecord.teams.map(
        (team, ix) => {
            const historicalTeam: Omit<HistoricalTeam, 'eloChange'> = {
                players: team.players.map((playerId) => ({
                    id: playerId,
                    previousElo: playersCurrentElo[playerId],
                })),
                outcome: getTeamOutcome(winningTeamIndices, ix),
                score: team.scores.reduce((prev, cur) => prev + cur, 0),
            };
            return historicalTeam;
        }
    );

    const eloChanges = getEloChanges(teamsWithoutEloChangeWithPlaceholder, winningTeamIndices);
    const teamsWithPlaceholder = teamsWithoutEloChangeWithPlaceholder.map(
        (teamWithoutElo, i): HistoricalTeam => ({ eloChange: eloChanges[i], ...teamWithoutElo })
    );
    const teams = teamsWithPlaceholder.map((teamWithPlaceholder) => {
        const team = { ...teamWithPlaceholder };
        team.players = team.players.filter((player) => player.id.indexOf(PLACEHOLDER_ID_PREFIX) === -1);
        return team;
    });

    const historicalGame: HistoricalGame = {
        timestamp: Date.now(),
        teams,
        gameType,
    };

    const playersNewElo: Record<string, number> = {};
    teams.forEach((team) => {
        team.players.forEach((player) => (playersNewElo[player.id] = player.previousElo + team.eloChange));
        if (placeholderElo && teamSize > team.players.length) placeholderElo.elo += team.eloChange;
    });
    if (placeholderElo) playersNewElo[placeholderElo.id] = placeholderElo.elo;
    return result({ historicalGame, playersNewElo });
}
