import assert from 'assert';
import { propertiesMatch } from '../../../util/util';
import { activateFishbowlGame, addPhrase, removePhrase } from '../../business/fishbowl/gameSetup';
import { savePhrases } from './phraseCollection';
import * as playerStats from '../playerStats';
import { getAllPhrases } from '../../business/pure/pure';
import { savePhrases as savePhrasesForPlayer } from './fishbowlStats';
import * as user from '../lobby';
import { inTranIfDefined, Result, success, failure } from '@playtime/database';
import db from '../../databases';
import { CodenamesConfig } from '@playtime/database/src/model/codenames';
import { FishbowlConfig } from '@playtime/database/src/model/fishbowl';
import { isFishbowlLobbyGame, GameType } from '@playtime/database/src/model/lobby';

export function enterPhrase(gameId: string, userId: string, phrase: string): Promise<Result> {
    return inTranIfDefined(db.lobbyGame, gameId, (game) =>
        isFishbowlLobbyGame(game)
            ? addPhrase(game, userId, phrase)
            : failure('Attempting to enter phrase for non-fishbowl game')
    );
}

export function deletePhrase(gameId: string, userId: string, phrase: string): Promise<Result> {
    return inTranIfDefined(db.lobbyGame, gameId, (game) =>
        isFishbowlLobbyGame(game)
            ? removePhrase(game, userId, phrase)
            : failure('Attempting to delete phrase for non-fishbowl game')
    );
}

export async function startFishbowlGame(
    gameId: string,
    teamNames: ReturnType<typeof user.generateTeams>
): Promise<Result> {
    const lobbyGameResult = await user.fetchLobbyGameType(gameId, GameType.Fishbowl);
    if (!lobbyGameResult.success) return lobbyGameResult;
    const games = {
        lobbyGame: lobbyGameResult.value,
        activeGame: await db.fishbowlGame.fetch(gameId),
    };
    if (!games.lobbyGame) return failure("Cannot start lobby game that doesn't exist");
    const playerIds = Object.keys(games.lobbyGame.players ?? {});
    if (!playerIds.length) return failure('Not enough players');

    const playersEloResult = await playerStats.getPlayersElo(playerIds, GameType.Fishbowl);
    if (!playersEloResult.success) return playersEloResult;
    const placeholderEloResult = await playerStats.getPlaceholderElo(
        playerIds.length,
        games.lobbyGame.config.numberOfTeams,
        GameType.Fishbowl
    );
    if (!placeholderEloResult.success) return placeholderEloResult;

    const result = activateFishbowlGame(games, teamNames, playersEloResult.value, placeholderEloResult.value);
    if (!result.success) return result;
    const activeGame = games.activeGame;
    assert(activeGame !== undefined, 'Active game should be defined');
    await db.fishbowlGame.set(gameId, activeGame);
    await db.lobbyGame.delete(gameId);
    await user.setGameType(playerIds, gameId, GameType.Fishbowl);
    await savePhrases(getAllPhrases(activeGame.players));
    for (const uid in activeGame.players) {
        await savePhrasesForPlayer(uid, Object.keys(activeGame.players[uid].phrases ?? {}));
    }
    return result;
}

export async function editGameConfig(gameId: string, newConfig: FishbowlConfig | CodenamesConfig): Promise<Result> {
    return inTranIfDefined(
        db.lobbyGame,
        gameId,
        (game) => {
            if (!propertiesMatch(game.config, newConfig))
                return failure('Tried to update with an improper config shape');
            game.config = newConfig;
            return success();
        },
        'Game to edit does not exist'
    );
}
