import { Paper, Grid, Icon, Typography } from '@mui/material';
import * as React from 'react';
import { getColorSchemeRgb } from '../../../../back-end';
import { useState } from 'react';
import { SyncedComponentProp } from '../../../hoc/sync';
import { Team, ActiveGame } from '@playtime/database/src/model/activeGame';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{
    isLeft: boolean;
    primaryColor?: [number, number, number];
    secondaryColor?: [number, number, number];
    darkFont?: boolean;
}>()((_, { isLeft, primaryColor, secondaryColor, darkFont }) => {
    const rgbColors = getColorSchemeRgb(primaryColor, secondaryColor);
    const styles: Record<string, Record<string, string | number>> = {
        teamFlagContainer: {
            zIndex: 1,
            position: 'relative',
            flexGrow: 1,
        },
        teamFlag: {
            marginTop: '4px',
            boxShadow: '0 4px 10px 0 rgba(40,40,40,.45)',
            color: darkFont ? '#666' : '#fff',
            position: 'absolute',
            width: 'calc(100% + 23px)',
            zIndex: 3,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        totalPoints: {
            backgroundImage: `linear-gradient( ${isLeft ? 135 : 45}deg, 
                rgb(${isLeft ? rgbColors.primary : rgbColors.secondary}) 0%, 
                rgb(${rgbColors.intermediate}) ${isLeft ? 70 : 30}%, 
                rgb(${isLeft ? rgbColors.secondary : rgbColors.primary}) 100%)`,
            boxShadow: '0 4px 10px 0 rgba(40,40,40,.45)',
            color: darkFont ? '#666' : '#fff',
            fontWeight: 'bold',
            width: '50px',
            height: '50px',
            display: 'flex',
            position: 'relative',
            zIndex: 4,
            marginTop: '4px',
            alignItems: 'center',
            borderRadius: '50%',
            justifyContent: 'center',
        },
        teamRoster: {
            backgroundColor: 'rgba(0,0,0,.5)',
            padding: '6px 6px 2px 6px',
            color: '#fff',
            top: 24,
            position: 'absolute',
            width: '100%',
            zIndex: 2,
        },
        rounds: {
            marginTop: -24,
            width: '44px',
            color: '#fff',
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,.5)',
            textAlign: 'center',
            paddingTop: 29,
        },
    };
    styles.teamFlag[isLeft ? 'left' : 'right'] = '-23px';
    styles.teamFlag.padding = `2px ${isLeft ? 7 : 30}px 2px ${isLeft ? 30 : 7}px`;
    styles.teamFlag.backgroundImage = `linear-gradient( 90deg, 
        rgb(${isLeft ? rgbColors.primary : rgbColors.secondary}) 0%, 
        rgb(${rgbColors.intermediate}) ${isLeft ? 70 : 30}%, 
        rgb(${isLeft ? rgbColors.secondary : rgbColors.primary}) 100%)`;
    styles.teamFlag.textAlign = isLeft ? 'start' : 'end';

    const opacity = 0.5;
    styles.teamRoster.backgroundImage = `linear-gradient( ${isLeft ? 135 : 45}deg, 
            rgba(${isLeft ? rgbColors.primary : rgbColors.secondary},${opacity}) 0%, 
            rgba(${rgbColors.intermediate},${opacity}) ${isLeft ? 70 : 30}%, 
            rgba(${isLeft ? rgbColors.secondary : rgbColors.primary},${opacity}) 100%)`;
    styles.teamRoster.textAlign = isLeft ? 'start' : 'end';

    styles.rounds.backgroundImage = `linear-gradient( ${isLeft ? 135 : 45}deg, 
        rgba(${isLeft ? rgbColors.primary : rgbColors.secondary},${opacity}) 0%, 
        rgba(${rgbColors.intermediate},${opacity}) ${isLeft ? 70 : 30}%, 
        rgba(${isLeft ? rgbColors.secondary : rgbColors.primary},${opacity}) 100%)`;
    if (!isLeft) styles.rounds.right = 2;
    return styles;
});

interface ScoreBannerProps {
    side: 'left' | 'right';
    teamName: string;
    team: SyncedComponentProp<Team & { scoreDisplay: number }>;
    roundIcons?: string[];
    players: SyncedComponentProp<ActiveGame['players']>;
}

const cache: Record<string, { showRoster: boolean; showRounds: boolean }> = {};
export const ScoreBanner: React.FunctionComponent<ScoreBannerProps> = (props) => {
    const isLeft = props.side === 'left';
    const { classes } = useStyles({
        isLeft,
        primaryColor: props.team?.primaryColor,
        secondaryColor: props.team?.secondaryColor,
        darkFont: props.team?.darkFont,
    });
    cache[props.teamName] = cache[props.teamName] ?? { showRoster: false, showRounds: false };
    const [showRoster, setShowRoster] = useState(cache[props.teamName].showRoster);
    const [showRounds, setShowRounds] = useState(cache[props.teamName].showRounds);

    if (!props.team) return null;

    const isPlayerTurn = (uid: string) => {
        return props.team?.playerTurn !== undefined && props.team?.players[props.team.playerTurn] === uid;
    };

    const toggleRounds = () => {
        setShowRounds(!showRounds);
        cache[props.teamName].showRounds = !showRounds;
    };
    const toggleRoster = () => {
        setShowRoster(!showRoster);
        cache[props.teamName].showRoster = !showRoster;
    };

    const orb = (
        <Grid item>
            <div className={classes.totalPoints} onClick={toggleRounds}>
                <Typography variant="h5">{props.team.scoreDisplay}</Typography>
            </div>
            {props.roundIcons && props.roundIcons.length > 0 && showRounds && (
                <Paper className={classes.rounds}>
                    {props.roundIcons.map((roundIcon, index) => (
                        <span key={roundIcon}>
                            <Icon fontSize="small">{roundIcon}</Icon>
                            &nbsp;
                            <span style={{ position: 'relative', top: -5 }}>{props.team?.scores[index]}</span>
                            <br />
                        </span>
                    ))}
                </Paper>
            )}
        </Grid>
    );
    const flag = (
        <Grid item className={classes.teamFlagContainer}>
            <Paper onClick={toggleRoster} elevation={2} className={classes.teamFlag}>
                {props.team.name}
            </Paper>
            {!showRoster ? null : (
                <Paper square={true} elevation={2} className={classes.teamRoster}>
                    {props.players &&
                        Object.values(props.team.players).map((uid, ix) => (
                            <span key={uid}>
                                {isLeft && isPlayerTurn(uid) ? <>&#9656;</> : null}
                                <span
                                    style={{
                                        marginLeft: isLeft && isPlayerTurn(uid) ? 0 : 11.86,
                                        marginRight: !isLeft && isPlayerTurn(uid) ? 0 : 11.86,
                                    }}
                                >
                                    {(props.players as ActiveGame['players'])[uid].displayName}
                                </span>
                                {!isLeft && isPlayerTurn(uid) ? <>&#9666;</> : null}
                                {!props.team || ix === props.team.players.length - 1 ? null : <br />}
                            </span>
                        ))}
                </Paper>
            )}
        </Grid>
    );

    return (
        <Grid container style={{ width: '100%' }}>
            {isLeft ? (
                <>
                    {orb}
                    {flag}
                </>
            ) : (
                <>
                    {flag}
                    {orb}
                </>
            )}
        </Grid>
    );
};
