import { Box, Button } from '@mui/material';
import { LobbyGame, GameType } from '@playtime/database/src/model/lobby';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { generateTeams } from '../../../back-end';
import { startCodenamesGame } from '../../../back-end/api/codenames/gameSetup';
import { getUser } from '../../../store/auth/selectors';
import { getSystemAppMode } from '../../../store/devTools/selectors';
import { NotOptional } from '../../../util/util';
import { SyncedComponentProps } from '../../hoc/sync';
import { BaseLobbyProps } from '../../Lobby/baseLobbyProps';

interface StartGameProps
    extends BaseLobbyProps,
        SyncedComponentProps<NotOptional<Pick<LobbyGame<GameType.Codenames>, 'players'>>> {
    host: string;
    numberOfTeams: number;
}

const StartGame: React.FunctionComponent<StartGameProps> = (props) => {
    const user = useSelector(getUser);
    const systemAppMode = useSelector(getSystemAppMode);
    const [startGameFailMsg, setStartGameFailMsg] = React.useState<string | undefined>(undefined);

    const isHost = props.host === user.uid;

    const isGameReady = (): boolean => {
        return systemAppMode === 'dev' ? true : Object.keys(props.players ?? {}).length >= props.numberOfTeams * 2;
    };

    const localStartGame = async () => {
        const teams = generateTeams(props.initialGameState.config.numberOfTeams);
        const result = await startCodenamesGame(props.gameId, teams);
        if (!result.success) {
            setStartGameFailMsg(result.message);
        }
    };

    return isGameReady() ? (
        isHost ? (
            <>
                <Box display="flex" justifyContent="center">
                    <Button variant="contained" color="primary" onClick={localStartGame}>
                        Start Game
                    </Button>
                </Box>
                {startGameFailMsg ? (
                    <Box display="flex" justifyContent="center">
                        {startGameFailMsg}
                    </Box>
                ) : undefined}
            </>
        ) : (
            <Box display="flex" justifyContent="center">
                Waiting for host to start...
            </Box>
        )
    ) : (
        <Box display="flex" justifyContent="center">
            Waiting for players...
        </Box>
    );
};

export default StartGame;
