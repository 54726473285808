import { StepConnector, Stepper, Step, StepLabel, StepIconProps, Tooltip, Icon, Typography } from '@mui/material';
import { Team } from '@playtime/database/src/model/activeGame';
import { FishbowlConfig, FishbowlGame } from '@playtime/database/src/model/fishbowl';
import clsx from 'clsx';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getColorSchemeRgb } from '../../../../back-end/business/pure/pure';
import { Immutable } from '../../../../util/util';
import { makeStyles, withStyles } from 'tss-react/mui';
import { SyncedComponentProp } from '../../../hoc/sync';

interface StepperIconProps {
    icon: string;
}

const useColorlibStepIconStyles = (
    primaryColor?: [number, number, number],
    secondaryColor?: [number, number, number],
    darkFont?: boolean
) => {
    const rgbColors = getColorSchemeRgb(primaryColor, secondaryColor);
    return makeStyles()({
        root: {
            backgroundColor: '#ccc',
            zIndex: 1,
            color: darkFont ? '#666' : '#fff',
            width: 40,
            height: 40,
            marginTop: 4,
            display: 'flex',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        active: {
            backgroundImage: `linear-gradient( 135deg, rgb(${rgbColors.secondary}) 0%, rgb(${rgbColors.intermediate}) 50%, rgb(${rgbColors.primary}) 100%)`,
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        },
        completed: {
            backgroundImage: `linear-gradient( 135deg, rgb(${rgbColors.secondary}) 0%, rgb(${rgbColors.intermediate}) 50%, rgb(${rgbColors.primary}) 100%)`,
        },
    });
};

const StepperConnector = (primaryColor?: [number, number, number], secondaryColor?: [number, number, number]) => {
    const rgbColors = getColorSchemeRgb(primaryColor, secondaryColor);
    return withStyles(StepConnector, {
        alternativeLabel: {
            top: 22,
        },
        active: {
            '& .line': {
                backgroundImage: `linear-gradient( 95deg,rgb(${rgbColors.primary}) 0%,rgb(${rgbColors.intermediate}) 50%,rgb(${rgbColors.secondary}) 100%)`,
            },
        },
        completed: {
            '& .line': {
                backgroundImage: `linear-gradient( 95deg,rgb(${rgbColors.primary}) 0%,rgb(${rgbColors.intermediate}) 50%,rgb(${rgbColors.secondary}) 100%)`,
            },
        },
        line: {
            height: 3,
            border: 0,
            backgroundColor: '#eaeaf0',
            borderRadius: 1,
        },
    });
};

const useStyles = makeStyles()({
    stepper: {
        padding: '5px 0',
    },
    label: {
        marginTop: '5px !important',
    },
});

export interface GameProgressProps {
    rounds: Immutable<FishbowlConfig>['rounds'];
    round: SyncedComponentProp<FishbowlGame['round']>;
    team: Team | undefined;
}

const GameProgress: React.FunctionComponent<GameProgressProps> = (props) => {
    const { classes } = useStyles();
    const [openToolTips, setOpenToolTips] = useState<boolean[]>(props.rounds.map(() => false));
    const { primaryColor, secondaryColor, darkFont } = props.team ?? {};

    const handleClick = (i: number) => {
        const modified = [...openToolTips];
        modified[i] = !openToolTips[i];
        setOpenToolTips(modified);
    };
    const handleClose = (i: number) => {
        const modified = [...openToolTips];
        modified[i] = false;
        setOpenToolTips(modified);
    };
    useEffect(() => {
        setOpenToolTips(props.rounds.map(() => false));
    }, []);

    function StepIcon(props: StepIconProps & StepperIconProps) {
        const { classes } = useColorlibStepIconStyles(primaryColor, secondaryColor, darkFont)();
        const { active, completed } = props;

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                <Icon>{props.icon}</Icon>
            </div>
        );
    }

    if (props.round === undefined || props.round === null) return null;
    const StepperConnectorColored = StepperConnector(primaryColor, secondaryColor);
    return props.round >= props.rounds.length ? (
        <>
            <Typography variant="h4" align="center">
                Game Over!
            </Typography>
            <Typography variant="subtitle1" align="center">
                The host can restart the game.
            </Typography>
        </>
    ) : (
        <Stepper
            className={classes.stepper}
            alternativeLabel
            activeStep={props.round}
            connector={<StepperConnectorColored />}
        >
            {props.rounds.map((round, index) => (
                <Step key={round.name}>
                    <Tooltip
                        title={round.description}
                        open={openToolTips[index]}
                        onClose={() => handleClose(index)}
                        placement="bottom"
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                    >
                        <StepLabel
                            StepIconComponent={StepIcon}
                            StepIconProps={{ icon: round.icon }}
                            classes={{ label: classes.label }}
                            onClick={() => handleClick(index)}
                        >
                            {props.round == index ? round.name : null}
                        </StepLabel>
                    </Tooltip>
                </Step>
            ))}
        </Stepper>
    );
};

export default GameProgress;
