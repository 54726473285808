import { Result, success, failure } from '@playtime/database';
import { CodenamesTeam, CodenamesGame } from '@playtime/database/src/model/codenames';
import { LobbyGame, GameType } from '@playtime/database/src/model/lobby';
import { generateTeams } from '../..';
import { PLACEHOLDER_ID_PREFIX } from '../../api/playerStats';
import { addPlaceholderPlayers } from '../fishbowl/gameplayCore';
import { balanceTeams } from '../pure/elo';

export function getBalancedTeams(
    playersElo: Record<string, number>,
    placeholderElo: { id: string; elo: number } | null,
    teamConfig: ReturnType<typeof generateTeams>
): CodenamesTeam[] {
    addPlaceholderPlayers(playersElo, placeholderElo, Object.keys(playersElo).length % teamConfig.length);
    const playersAndEloWithPlaceholders = Object.entries(playersElo);

    const balancedTeamsWithPlaceholders = balanceTeams(playersAndEloWithPlaceholders, teamConfig.length);
    const balancedTeams = balancedTeamsWithPlaceholders.map((x) =>
        x.filter((y) => y.indexOf(PLACEHOLDER_ID_PREFIX) === -1)
    );

    return balancedTeams
        .map((team, teamIndex) => ({
            scores: [0],
            ...teamConfig[teamIndex],
            playerTurn: 0,
            players: team,
            isReady: false,
        }))
        .sort((a, b) => a.players.length - b.players.length);
}

export function activateCodenamesGame(
    games: { lobbyGame?: LobbyGame<GameType.Codenames>; activeGame?: CodenamesGame },
    teamConfig: ReturnType<typeof generateTeams>,
    playersElo: Record<string, number>,
    placeholderElo: { id: string; elo: number } | null
): Result {
    const gameToStart = games.lobbyGame;
    if (gameToStart === undefined) {
        return failure('Game to start does not exist');
    }
    if (games.activeGame !== undefined) {
        return failure('Active game with same id already exists');
    }

    const playerList = Object.entries(gameToStart.players ?? {});
    const players: CodenamesGame['players'] = Object.fromEntries(
        playerList.map(([id, player]) => [id, { ...player, isActive: true }])
    );
    if (!players) {
        return failure('Game to start has no players');
    }

    // Assign teams
    const numTeams = gameToStart.config.numberOfTeams;
    if (numTeams < 1) {
        return failure('Expected positive number of teams');
    }
    if (teamConfig.length !== numTeams) {
        return failure('Unexpected number of team names');
    }

    if (playerList.length < numTeams) return failure('Fewer players than teams');
    if (Object.keys(playersElo).length < playerList.length) return failure('Not all players have elo assignment');
    const teams = getBalancedTeams(playersElo, placeholderElo, teamConfig);

    // Initialize active game
    const game: CodenamesGame = {
        name: gameToStart.name,
        host: gameToStart.host,
        config: gameToStart.config,
        gameType: GameType.Codenames,
        words: {},
        players,
        teamTurn: 0,
        teams,
        gameLog: [],
        clueGiven: false,
        turn: 0,
        streak: [],
    };

    // Move game from lobby to active
    delete games.lobbyGame;
    games.activeGame = game;
    return success();
}
