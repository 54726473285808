import { CodenamesGame } from '@playtime/database/src/model/codenames';
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';
import * as React from 'react';
import db from '../../../../back-end/databases';
import { dismissNotification } from '../../../common/snackbar';
import ScoreBanners from '../../../Fishbowl/Game/GameHeader/ScoreBanners';
import { Sync, SyncedComponentProps } from '../../../hoc/sync';
import { BaseCodenamesProps } from '../../../models/game';

function notifyPlayerCountChange(
    players: CodenamesGame['players'],
    notify: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
    notificationConfig: OptionsObject
) {
    // notify players when players leave and join game. will
    // probably be a candidate when we do big notification module refactor
    const prevActivePlayers: string[] | null = JSON.parse(sessionStorage.getItem('players') ?? 'null');
    const curActivePlayers = Object.keys(players).filter((id) => (players as CodenamesGame['players'])[id].isActive);
    sessionStorage.setItem('players', JSON.stringify(curActivePlayers));
    if (prevActivePlayers == null) return;

    if (prevActivePlayers.length > curActivePlayers.length) {
        for (const id in curActivePlayers) prevActivePlayers.splice(prevActivePlayers.indexOf(id), 1);
        const missingPlayersText = prevActivePlayers
            .map((id) => (players as CodenamesGame['players'])[id]?.displayName)
            .join(', ');
        notify(`${missingPlayersText} has left the game.`, {
            ...notificationConfig,
            variant: 'warning',
        });
    } else if (prevActivePlayers.length < curActivePlayers.length) {
        for (const id in prevActivePlayers) curActivePlayers.splice(curActivePlayers.indexOf(id), 1);
        const extraPlayersText = curActivePlayers
            .map((id) => (players as CodenamesGame['players'])[id].displayName)
            .join(', ');
        notify(`${extraPlayersText} has joined the game.`, notificationConfig);
    }
}

const GameHeader: React.FunctionComponent<
    BaseCodenamesProps & SyncedComponentProps<Pick<CodenamesGame, 'teams' | 'players'>>
> = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const playerJoinLeaveSnackbarConfig: OptionsObject = {
        autoHideDuration: 5000,
        variant: 'info',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        action: dismissNotification(closeSnackbar),
    };
    React.useEffect(() => sessionStorage.removeItem('players'), []);
    props.players?.length && notifyPlayerCountChange(props.players, enqueueSnackbar, playerJoinLeaveSnackbarConfig);

    const SyncedScoreBanners = Sync(ScoreBanners);
    const getScoreForTeamn = (team: CodenamesGame['teams'][number]) => {
        const totalClues =
            props.initialGameState.config.numberOfClues + (props.initialGameState.teams[0].name === team.name ? 1 : 0);
        return totalClues - team.scores[0];
    };
    return (
        <SyncedScoreBanners
            gameId={props.gameId}
            initialGameState={props.initialGameState}
            gameDb={db.codenamesGame}
            teams={props.teams?.map((team) => ({ ...team, scoreDisplay: getScoreForTeamn(team) }))}
            players={props.players}
        />
    );
};

export default GameHeader;
