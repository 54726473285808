import assert from 'assert';
import { activateCodenamesGame } from '../../business/codenames/gameSetup';
import { generateTeams } from '../lobby';
import * as playerStats from '../playerStats';
import * as user from '../lobby';
import * as gameplay from './gameplay';
import * as joinableActiveGame from '../joinableActiveGame';
import db from '../../databases';
import { GameType } from '@playtime/database/src/model/lobby';
import { Result, failure } from '@playtime/database';

export async function startCodenamesGame(gameId: string, teamNames: ReturnType<typeof generateTeams>): Promise<Result> {
    const lobbyGameResult = await user.fetchLobbyGameType(gameId, GameType.Codenames);
    if (!lobbyGameResult.success) return lobbyGameResult;
    const games = {
        lobbyGame: lobbyGameResult.value,
        activeGame: await db.codenamesGame.fetch(gameId),
    };
    if (!games.lobbyGame) return failure("Cannot start lobby game that doesn't exist");
    const playerIds = Object.keys(games.lobbyGame.players ?? {});
    if (!playerIds.length) return failure('Not enough players');

    const playersEloResult = await playerStats.getPlayersElo(playerIds, GameType.Codenames);
    if (!playersEloResult.success) return playersEloResult;
    const placeholderEloResult = await playerStats.getPlaceholderElo(
        playerIds.length,
        games.lobbyGame.config.numberOfTeams,
        GameType.Codenames
    );
    if (!placeholderEloResult.success) return placeholderEloResult;

    const result = activateCodenamesGame(games, teamNames, playersEloResult.value, placeholderEloResult.value);
    if (!result.success) return result;
    const activeGame = games.activeGame;
    assert(activeGame !== undefined, 'Active game should be defined');
    await db.codenamesGame.set(gameId, activeGame);
    await db.lobbyGame.delete(gameId);
    if (activeGame.config.allowLateJoin)
        await joinableActiveGame.trackJoinableActiveGame(gameId, GameType.Codenames, activeGame.name);
    await user.setGameType(playerIds, gameId, GameType.Codenames);
    await gameplay.notifyPlayers(gameId);
    return result;
}
