import { Box, Divider, List } from '@mui/material';
import { CodenamesGame, isClue } from '@playtime/database/src/model/codenames';
import * as React from 'react';
import OutlinedLabel from '../../../../common/OutlinedLabel';
import { SyncedComponentProps } from '../../../../hoc/sync';
import { BaseCodenamesProps } from '../../../../models/game';
import { getColor } from '../../utility';
import Log from './Log';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    anchorBottom: {
        position: 'fixed',
        marginLeft: 'auto',
        marginRight: 'auto',
        bottom: 5,
        textAlign: 'center',
    },
    fullWidth: {
        width: '100%',
    },
    scrollableFixedHeight: {
        height: '30vh',
        overflow: 'auto',
    },
});

const GameLog: React.FunctionComponent<
    SyncedComponentProps<Pick<CodenamesGame, 'gameLog' | 'words'>> & BaseCodenamesProps
> = (props) => {
    const { classes } = useStyles();
    if (!props.gameLog?.length) return null;

    return (
        <OutlinedLabel label="Game Log">
            <Box className={classes.scrollableFixedHeight}>
                <List className={classes.fullWidth} dense>
                    {props.gameLog.map((log) => {
                        if (!props.words) return null;
                        const logTeam = props.initialGameState.teams[isClue(log) ? log.clueGiverTeam : log.guesserTeam];
                        const wordColor = isClue(log)
                            ? undefined
                            : getColor(props.words[log.word], props.initialGameState.teams);
                        const uniqueKey = `${isClue(log) ? log.clueGiverName + log.clue : log.guesserTeam + log.word}${
                            log.turn
                        }`;
                        return (
                            <React.Fragment key={uniqueKey}>
                                <Log log={log} logTeam={logTeam} wordColor={wordColor} />
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        );
                    })}
                </List>
            </Box>
        </OutlinedLabel>
    );
};

export default GameLog;
