import { Grid } from '@mui/material';
import { CodenamesGame, Word } from '@playtime/database/src/model/codenames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getTeamForPlayer, isPlayersTurn } from '../../../../back-end';
import { selectWord } from '../../../../back-end/api/codenames/gameplay';
import { getUser } from '../../../../store/auth/selectors';
import { SyncedComponentProps } from '../../../hoc/sync';
import { BaseCodenamesProps } from '../../../models/game';
import { getColor } from '../utility';
import WordCard from './WordCard';

const WordGrid: React.FunctionComponent<
    SyncedComponentProps<Pick<CodenamesGame, 'words' | 'teams' | 'teamTurn'>> & BaseCodenamesProps
> = (props) => {
    const user = useSelector(getUser);
    if (!props.words || !Array.isArray(props.teams)) return null;
    const { team } = getTeamForPlayer(props.teams, user.uid);
    if (!team) return null;

    const isPlayerClueGiver = isPlayersTurn(team, user.uid);
    const gameOver = props.teams.some((t) => !t.isReady);

    const onWordClick = (word: Word) => {
        if (word.revealed) return;
        selectWord(props.gameId, user.uid, word);
    };

    return (
        <Grid container justifyContent="space-around" alignItems="center" spacing={1}>
            {Object.entries(props.words).map(([val, word]) => {
                if (!props.teams || props.teamTurn === undefined || props.teamTurn === null) return null;
                const { darkFont, primaryColor, secondaryColor } = getColor(word, props.teams);
                return (
                    <Grid item key={val}>
                        <WordCard
                            onClick={() => onWordClick(word)}
                            {...word}
                            isPlayerClueGiver={gameOver || isPlayerClueGiver}
                            numberOfVotes={props.teams[props.teamTurn].players.length - 1}
                            primaryColor={primaryColor}
                            darkFont={darkFont}
                            secondaryColor={secondaryColor}
                        ></WordCard>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default WordGrid;
