import { Button } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/auth/selectors';
import { generateTeams, startFishbowlGame } from '../../../back-end';
import { BaseLobbyProps } from '../../Lobby/baseLobbyProps';
import { NotOptional } from '../../../util/util';
import { getSystemAppMode } from '../../../store/devTools/selectors';
import { GameType, LobbyGame } from '@playtime/database/src/model/lobby';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    centeredContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    settingsIcon: {
        padding: '.35em .1em',
    },
}));

interface StartGameProps extends BaseLobbyProps, NotOptional<Pick<LobbyGame<GameType.Fishbowl>, 'players'>> {
    phraseCount: number;
    numberOfTeams: number;
    host: string;
}

export const StartGame: React.FunctionComponent<StartGameProps> = (props) => {
    const { classes } = useStyles();
    const user = useSelector(getUser);
    const systemAppMode = useSelector(getSystemAppMode);
    const [startGameFailMsg, setStartGameFailMsg] = React.useState<string | undefined>(undefined);

    const isHost = props.host === user.uid;
    const playerEntries = Object.entries(props.players ?? {});

    const isGameReady = (): boolean => {
        const allPhrasesEntered = playerEntries.every(
            ([, p]) => Object.keys(p.phrases ?? {}).length === props.phraseCount
        );
        const validNumberOfPlayers = systemAppMode === 'dev' ? true : playerEntries.length >= props.numberOfTeams * 2;
        return validNumberOfPlayers && allPhrasesEntered;
    };

    const localStartGame = async () => {
        const teams = generateTeams(props.numberOfTeams);
        const result = await startFishbowlGame(props.gameId, teams);
        if (!result.success) {
            setStartGameFailMsg(result.message);
        }
    };

    if (!isGameReady()) return <div className={classes.centeredContainer}>Waiting for phrases...</div>;

    return isHost ? (
        <>
            <div className={classes.centeredContainer}>
                <Button variant="contained" color="primary" onClick={localStartGame}>
                    Start Game
                </Button>
            </div>
            {startGameFailMsg ? <div className={classes.centeredContainer}>{startGameFailMsg}</div> : undefined}
        </>
    ) : (
        <div className={classes.centeredContainer}>Waiting for host to start...</div>
    );
};
