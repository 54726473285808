import { Grid } from '@mui/material';
import { Database } from '@playtime/database';
import { ActiveGame, Team } from '@playtime/database/src/model/activeGame';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getTeamForPlayer } from '../../../../back-end';
import { getUser } from '../../../../store/auth/selectors';
import { SyncedComponentProp } from '../../../hoc/sync';
import { BaseGameProps } from '../../../models/game';
import { ScoreBanner } from './ScoreBanner';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    vs: {
        fontSize: '1.2rem',
        fontWeight: 600,
        paddingTop: '3px',
    },
});

interface ScoreBannersProps extends BaseGameProps {
    roundIcons?: string[];
    gameDb: Database<ActiveGame, string, Record<string, unknown>>;
    teams: SyncedComponentProp<(Team & { scoreDisplay: number })[]>;
    players: SyncedComponentProp<ActiveGame['players']>;
}

const ScoreBanners: React.FunctionComponent<ScoreBannersProps> = (props) => {
    const { classes } = useStyles();
    const user = useSelector(getUser);

    if (!props.teams) return null;
    const myTeam = getTeamForPlayer(props.teams, user.uid);
    if (!myTeam.team) return null;

    return (
        <Grid container justifyContent="space-between">
            <Grid item style={{ width: '45%' }}>
                <ScoreBanner
                    players={props.players}
                    roundIcons={props.roundIcons}
                    side={'left'}
                    teamName={myTeam.team.name}
                    team={myTeam.team}
                />
            </Grid>
            {props.teams.length <= 1 ? null : (
                <Grid item className={classes.vs}>
                    vs
                </Grid>
            )}
            <Grid item style={{ width: '45%' }}>
                {props.teams.map((t, i) =>
                    i === myTeam.index ? null : (
                        <ScoreBanner
                            key={t.name}
                            players={props.players}
                            roundIcons={props.roundIcons}
                            side={'right'}
                            teamName={t.name}
                            team={t}
                        />
                    )
                )}
            </Grid>
        </Grid>
    );
};

export default ScoreBanners;
