import { Team } from '@playtime/database/src/model/activeGame';
import { HistoricalTeam } from '@playtime/database/src/model/historicalGames';
import assert from 'assert';

/** The factor that controls how lenient an elo change is for differing ratings.
 * The lower n, the less lenient for the underdog.
 */
const n = 400;

/** The factor that linearly scales the elo change amount.
 * The higher k, the more volatile the ratings are.
 * The lower k, the harder it is to climb or fall in the ladder.
 * TODO: this could be dynamic such that k starts higher for newer
 * players and therefore have a more "elastic" rating (placement matches)
 */
const k = 30;

function calcExpectedWinRate(A: number, B: number): number {
    const x = A - B;
    const exp = -1 * (x / n);
    return 1 / (1 + Math.pow(10, exp));
}

function calcEloChange(A: number, B: number, AWins: boolean, contestantCount: number): number {
    let factor = calcExpectedWinRate(A, B);
    if (AWins) factor = 1 - factor;
    const multiTeamDampener = 1 / (contestantCount - 1);
    return Math.round(k * multiTeamDampener * factor);
}

function getTeamEloAverage(team: Pick<HistoricalTeam, 'players'>) {
    return team.players.reduce((sum, player) => sum + player.previousElo, 0) / team.players.length;
}

export function getEloChanges(teams: Pick<HistoricalTeam, 'players'>[], winningTeamIndices: number[]) {
    const eloChanges = teams.map(() => 0);
    const eloAverages = teams.map((team) => getTeamEloAverage(team));
    for (let i = 0; i < eloChanges.length; i++) {
        for (let j = i + 1; j < eloChanges.length; j++) {
            // team i is a winner
            if (winningTeamIndices.indexOf(i) !== -1) {
                const eloChange = calcEloChange(eloAverages[i], eloAverages[j], true, teams.length);
                eloChanges[i] += eloChange;
                eloChanges[j] -= eloChange;
            }
            // team j is a winner
            // note: in the event both are winners, it's a tie. ties are calculated
            // by the elo change of the loss plus the elo change of the win
            if (winningTeamIndices.indexOf(j) !== -1) {
                const eloChange = calcEloChange(eloAverages[j], eloAverages[i], true, teams.length);
                eloChanges[j] += eloChange;
                eloChanges[i] -= eloChange;
            }
        }
    }
    return eloChanges;
}

/** Joins the two lists:
 * 1. curSetList and
 * 2. prevSetList with newItem added to every prevSetList set  */
function joinSetLists(curSetList: Set<string>[], prevSetList: Set<string>[], newItem: string): Set<string>[] {
    return curSetList.concat(
        prevSetList.map((prevSet) => {
            const prevSetCopy = new Set(prevSet);
            prevSetCopy.add(newItem);
            return prevSetCopy;
        })
    );
}

function areSetsDistinct(set1: Set<string>, set2: Set<string>) {
    for (const set1Item of set1) {
        if (set2.has(set1Item)) return false;
    }
    return true;
}

type DpLocation = {
    dpIx: number;
    dpSetIx: number;
};
type TeamPossibilities = {
    playerSet: Set<string>;
    dpLocations: DpLocation[];
}[];
/** For a given i in dp, track any set of players that equal teamSize and
 * are distinct to the existing players in a possible team set. Return
 * the dp location of the first team set to have all players in it. */
function checkPlayerCombos(
    dp: Set<string>[][],
    teamPossibilities: TeamPossibilities,
    i: number,
    playerCount: number,
    teamSize: number
): DpLocation[] | null {
    const dpi = dp[i];
    for (let j = 0; j < dpi.length; j++) {
        if (dpi[j].size !== teamSize) continue;
        const dpLocation = { dpIx: i, dpSetIx: j };
        const teamPossibilitiesLength = teamPossibilities.length;
        for (let k = 0; k < teamPossibilitiesLength; k++) {
            if (!areSetsDistinct(dpi[j], teamPossibilities[k].playerSet)) continue;
            const possibility = {
                playerSet: new Set([...teamPossibilities[k].playerSet, ...dpi[j]]),
                dpLocations: [...teamPossibilities[k].dpLocations, dpLocation],
            };
            teamPossibilities.push(possibility);
            if (possibility.playerSet.size === playerCount) return possibility.dpLocations;
        }
        const possibility = { playerSet: new Set([...dpi[j]]), dpLocations: [dpLocation] };
        teamPossibilities.push(possibility);
        if (possibility.playerSet.size === playerCount) return possibility.dpLocations;
    }
    return null;
}

/** Given a dp array representing sets of players that sum to i and a target, return the closest set of numTeams teams to the target.
 * Each team size should be equal to playerCount / numTeams and all the players should be distinct and appear only once in a team.
 */
function getClosestCombinationToTarget(dp: Set<string>[][], playerCount: number, numTeams: number, target: number) {
    const teamSize = playerCount / numTeams;
    // build a list of possible teams until we have 1 list of teams that include all players. That will be our answer
    const teamPossibilities: TeamPossibilities = [];
    let teams: DpLocation[] | null = null;
    // start from the target and move outward
    for (let i = 0; i < dp.length - target; i++) {
        // check upward
        const upper = target + i;
        teams = checkPlayerCombos(dp, teamPossibilities, upper, playerCount, teamSize);
        if (teams !== null) break;

        // check downward
        if (i === 0 || target - i < 1) continue;
        const lower = target - i;
        teams = checkPlayerCombos(dp, teamPossibilities, lower, playerCount, teamSize);
        if (teams !== null) break;
    }
    assert(teams, 'Should have found at least 1 valid combination of players');
    return teams.map((team) => [...dp[team.dpIx][team.dpSetIx]]);
}

export function balanceTeams(playersElo: [string, number][], numTeams: number): string[][] {
    // this function expects all teams to have equal number of players.
    // implementations should use the 'placeholder_#' players' elos.
    assert(
        playersElo.length % numTeams === 0,
        'Invalid number of players. Players must be divisible by number of teams'
    );

    // only need the relative differences. we can subtract off the min elo to get the dp array smaller.
    const minElo = playersElo.reduce<number>((min, playerElo) => Math.min(playerElo[1], min), 10000) - 1;
    playersElo.forEach((playerElo) => (playerElo[1] -= minElo));

    // dynamic programming: dp represents the possible sums of elo.
    // where i = the sum, dp[i] = all the sets of players that sum to that i.
    const dp: Set<string>[][] = [];
    const eloSum = playersElo.reduce<number>((sum, playerElo) => sum + playerElo[1], 0);
    const target = Math.floor(eloSum / numTeams);
    dp.push([new Set<string>()]);
    for (let i = 1; i <= eloSum; i++) dp.push([]);

    for (const [id, elo] of playersElo) {
        for (let i = eloSum; i > 0; i--) {
            if (i - elo >= 0 && dp[i - elo].length) {
                // we can sum to i with the current player added to all the sets of dp[i - elo]
                dp[i] = joinSetLists(dp[i], dp[i - elo], id);
            }
        }
    }

    return getClosestCombinationToTarget(dp, playersElo.length, numTeams, target);
}

export function getTeamEloSum(team: Team, playersElo: Record<string, number>) {
    return team.players.reduce((totalElo, player) => totalElo + playersElo[player], 0);
}
