import { PlayerOutcome, STARTING_ELO } from '../playerStats';
import * as commonApi from '../../api/playerStats';
import * as core from '../../business/codenames/stats';
import db from '../../databases';
import { mergeResults, inTranDefault, success } from '@playtime/database';
import { GameType } from '@playtime/database/src/model/lobby';
import { CodenamesStats, StatsType } from '@playtime/database/src/model/stats';

export const defaultCodenamesStats = (): CodenamesStats => ({
    winCount: 0,
    lossCount: 0,
    tieCount: 0,
    elo: STARTING_ELO,
    games: [],
    streaks: {},
    clueGiverCount: 0,
    assassinCount: 0,
    bystandardCount: 0,
    enemyAgentCount: 0,
    assassinGuesserCount: 0,
    bystandardGuesserCount: 0,
    allyAgentGuesserCount: 0,
    enemyAgentGuesserCount: 0,
});

export async function streaked(
    players: readonly string[],
    clueGiver: string,
    combo: string[],
    streakEnder: 'victory' | 'pass' | 'assassin' | 'bystandard' | 'opposingTeam'
) {
    return mergeResults(
        await Promise.all(
            players.map((playerId) =>
                inTranDefault(
                    db.playerStats,
                    playerId,
                    (playerStats) => core.streaked(playerStats, playerId === clueGiver, combo, streakEnder),
                    commonApi.defaultPlayerStats()
                )
            )
        )
    );
}

export async function gameOver(
    playersOutcome: PlayerOutcome[],
    playersNewElo: Record<string, number>,
    historicalGameId: string,
    clueGivers: string[]
) {
    const clueGiverUpdateResults = await Promise.all(
        clueGivers.map((clueGiverId) =>
            inTranDefault(
                db.playerStats,
                clueGiverId,
                (playerStats) => {
                    playerStats[StatsType.Codenames].clueGiverCount++;
                    return success();
                },
                commonApi.defaultPlayerStats()
            )
        )
    );
    return mergeResults([
        ...clueGiverUpdateResults,
        await commonApi.gameOver(playersOutcome, playersNewElo, historicalGameId, GameType.Codenames),
    ]);
}
