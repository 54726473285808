import * as React from 'react';
import db from '../../../back-end/databases';
import { Sync, syncDataItem } from '../../hoc/sync';
import { BaseCodenamesProps } from '../../models/game';
import GameContent from './GameContent/GameContent';
import GameHeader from './GameHeader/GameHeader';

const InGame: React.FunctionComponent<BaseCodenamesProps> = (props) => {
    const syncCodenames = syncDataItem(db.codenamesGame, props.gameId);
    const SyncGameContent = Sync(GameContent);
    const SyncGameHeader = Sync(GameHeader);
    return (
        <>
            <SyncGameHeader {...props} {...syncCodenames.props('teams', 'players')} />
            <br />
            <SyncGameContent
                {...syncCodenames.props('teams', 'teamTurn', 'words', 'clueGiven', 'gameLog')}
                {...props}
            />
        </>
    );
};

export default InGame;
