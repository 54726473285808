import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ActiveGame } from '@playtime/database/src/model/activeGame';
import * as React from 'react';
import { getTextColor } from '../../common/styles';

const useStyles = makeStyles({
    teamText: (props: TeamProps) => ({
        ...getTextColor(props.team.darkFont ?? false, props.team.primaryColor, props.team.secondaryColor),
        textAlign: props.align,
    }),
    list: (props: TeamProps) => ({
        textAlign: props.align,
        listStyleType: 'none',
        paddingLeft: 0,
        marginTop: 0,
    }),
});

interface TeamProps {
    team: ActiveGame['teams'][number];
    players: ActiveGame['players'];
    playersTurn: string[];
    align: 'left' | 'right';
}

const Team: React.FunctionComponent<TeamProps> = (props) => {
    const classes = useStyles(props);
    return (
        <>
            <Typography variant="subtitle1" className={classes.teamText}>
                {props.team.name}
            </Typography>
            <ul className={classes.list}>
                {props.team.players.map((player) => {
                    if (!props.players) return;
                    return (
                        <li key={player}>
                            {props.align === 'right' && props.playersTurn.includes(player) ? <>&#9656;</> : null}
                            <Typography variant="caption">{props.players[player].displayName}</Typography>
                            {props.align === 'left' && props.playersTurn.includes(player) ? <>&#9666;</> : null}
                        </li>
                    );
                })}
            </ul>
        </>
    );
};

export default Team;
