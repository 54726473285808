import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { fetchAppSettings, setFirebaseApp } from '../store/appSettings/actions';
import { setUser, logout, setFirebaseAuth } from '../store/auth/actions';
import { setSystemAppMode, setSystemDbMode } from '../store/devTools/actions';

const setupFirebase = async () => {
    const appSettings = await fetchAppSettings();

    const firebaseConfig = {
        apiKey: 'AIzaSyDCufMWESd4lYxx5gy9UU70UianqrJ1AVM',
        authDomain: 'play-time-356f8.firebaseapp.com',
        databaseURL: appSettings.databaseURL ?? '',
        projectId: 'play-time-356f8',
        storageBucket: 'play-time-356f8.appspot.com',
        messagingSenderId: '194896646529',
        appId: '1:194896646529:web:535a6442829b682e4a9880',
        measurementId: 'G-466G6K7GML',
    };
    const firebaseApp = initializeApp(firebaseConfig);

    const auth = await getAuth(firebaseApp);
    onAuthStateChanged(auth, function (user) {
        if (user) setUser(user);
        else logout();
    });
    setSystemAppMode(appSettings.debugEnabled ? 'dev' : 'prod');
    setSystemDbMode(appSettings.debugEnabled ? 'dev' : 'prod');
    setFirebaseApp(firebaseApp);
    setFirebaseAuth(auth);
};

setupFirebase().catch(console.log);
