import { DatabaseReference } from "firebase/database";
import { Database } from "..";
import { FirebaseDatabase } from "../core";
import { HistoricalGame } from "../model/historicalGames";
import { Reference } from "@firebase/database-types";

export function getHistoricalGamesDb(
  getDbRef: () => DatabaseReference | Reference
): Database<HistoricalGame, string> {
  return new FirebaseDatabase<HistoricalGame, string>(
    getDbRef,
    "historicalGames"
  );
}
