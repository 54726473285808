import { DatabaseReference } from "firebase/database";
import { Database } from "..";
import { FirebaseDatabase } from "../core";
import { LobbyGame } from "../model/lobby";
import { Reference } from "@firebase/database-types";

export function getLobbyGameDb(
  getDbRef: () => DatabaseReference | Reference
): Database<LobbyGame, string> {
  return new FirebaseDatabase<LobbyGame, string>(getDbRef, "lobbyGames");
}
