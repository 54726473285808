import { CodenamesConfig } from '@playtime/database/src/model/codenames';
import { LobbyGame, GameType } from '@playtime/database/src/model/lobby';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { leaveGame } from '../../../back-end';
import { registerForGameNotifications } from '../../../back-end/api/messaging';
import db from '../../../back-end/databases';
import { getUser } from '../../../store/auth/selectors';
import { Sync, syncDataItem, SyncedComponentProp, SyncPropEvents } from '../../hoc/sync';
import { BaseCodenamesProps } from '../../models/game';
import StartGame from './StartGame';

const Lobby: React.FunctionComponent<
    BaseCodenamesProps & SyncedComponentProp<Pick<CodenamesConfig, 'sendNotifications'>>
> = (props) => {
    const SyncedStartGame = Sync(StartGame);
    const syncedLobbyGame = syncDataItem(db.lobbyGame, props.gameId);
    const syncedConfig = syncedLobbyGame.prop('config');
    const user = useSelector(getUser);
    React.useEffect(() => {
        const setupNotifications = async () => {
            if (!props.sendNotifications) return;
            const notifyUnsubscribe = await registerForGameNotifications(user.uid, props.gameId, 'lobbyGames');
            if (!notifyUnsubscribe) leaveGame(user.uid, props.gameId);
        };
        setupNotifications();
    }, [props.sendNotifications]);

    return (
        <SyncedStartGame
            {...props}
            host={syncedLobbyGame.prop('host')}
            players={syncedLobbyGame.prop('players') as SyncPropEvents<LobbyGame<GameType.Codenames>['players']>}
            numberOfTeams={syncedConfig.prop('numberOfTeams')}
        />
    );
};

export default Lobby;
