import * as codenames from './codenames/gameplay';
import { failure } from '@playtime/database';
import db from '../databases';
import { GameType } from '@playtime/database/src/model/lobby';

export async function trackJoinableActiveGame(id: string, gameType: GameType, name: string) {
    return await db.joinableActiveGames.set(id, { id, gameType, name });
}

export async function joinActiveGame(id: string, userId: string, displayName: string, gameType: GameType) {
    if (gameType === GameType.Codenames) return await codenames.joinAfterGameStart(id, userId, displayName);
    return failure('cannot join this game type after game start yet');
}
