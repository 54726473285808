export type Result<T = void> = FailedResult | SuccessfulResult<T>;

export type FailedResult = {
  success: false;
  message: string;
};

export type SuccessfulResult<T = void> = {
  success: true;
  value: T;
};

export function success(): Result<void> {
  return { success: true, value: undefined };
}

export function result<T>(value: T): Result<T> {
  return { success: true, value };
}

export function failure<T>(message: string): Result<T> {
  return { success: false, message };
}

type FlattenedOrVoid<T> = T extends undefined | void | null
  ? void
  : T extends []
  ? T
  : T[];
export function mergeResults<T, R = FlattenedOrVoid<T>>(
  results: Result<T>[]
): Result<R> {
  if (!results.length) return success() as Result<R>;
  let failureMessage = "";
  let successful: T[] = [];
  for (const result of results) {
    if (!result.success) {
      failureMessage += `\n${result.message}`;
      continue;
    }
    if (failureMessage || !result.value) continue;
    if (Array.isArray(result.value))
      successful = successful.concat(result.value);
    else successful.push(result.value);
  }
  if (failureMessage) return failure(failureMessage);
  if (!successful.length) return success() as Result<R>;
  return result(successful) as Result<R>;
}
