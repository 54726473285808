import { getMessaging } from '@firebase/messaging';
import { Result, result, success, failure } from '@playtime/database';
import assert from 'assert';
import Axios, { AxiosResponse } from 'axios';
import { getToken } from 'firebase/messaging';
import store from '../../store';
import { getBackendUri, getFirebaseApp, getVapidKey } from '../../store/appSettings/selectors';
import * as core from '../business/messaging';
import db from '../databases';

export interface Notification {
    deviceToken: string;
    title: string;
    body: string;
    gameId: string;
    userId: string;
}

async function sendNotifications(notifications: Notification[]) {
    const state = store.store.getState();
    const backendUri = getBackendUri(state);
    return Promise.all(
        notifications.map((notification) => Axios.post(`${backendUri}/api/sendNotification`, notification))
    );
}

export async function registerForGameNotifications(
    userId: string,
    gameId: string,
    lobbyOrGame: 'codenameGames' | 'lobbyGames'
): Promise<boolean> {
    const state = store.store.getState();
    const firebaseApp = getFirebaseApp(state);
    const backendUri = getBackendUri(state);
    const vapidKey = getVapidKey(state);
    assert(firebaseApp, 'cannot get messaging token before firebase is loaded');

    const messaging = getMessaging(firebaseApp);
    const token = await getToken(messaging, {
        vapidKey,
    });
    if (token) {
        await Axios.post(`${backendUri}/api/registerForGameNotifications`, {
            userId,
            gameId,
            lobbyOrGame,
            token,
        });

        return true;
    }
    return false;
}

export async function queueReminders(notifications: Notification[]) {
    const state = store.store.getState();
    const backendUri = getBackendUri(state);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const playersReminderCancellationUriPromises = notifications.reduce<Promise<AxiosResponse<any> | string>[]>(
        (promises, notification) => {
            promises.push(Promise.resolve(notification.userId));
            promises.push(Axios.post(`${backendUri}/api/orchestrators/ReminderNotification`, notification));
            return promises;
        },
        []
    );
    const cancellationUris = await Promise.all(playersReminderCancellationUriPromises);
    const playersReminderCancellationUri: Record<string, string> = {};
    for (let i = 0; i + 1 < cancellationUris.length; i += 2) {
        const playerId = cancellationUris[i] as string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const orchestratorResponse = cancellationUris[i + 1] as AxiosResponse<any>;
        playersReminderCancellationUri[playerId] = orchestratorResponse.data.terminatePostUri;
    }
    return playersReminderCancellationUri;
}

/**
 * Notifies players and queues up a notification reminder
 * @param gameId id of game
 * @returns an object of key playerId and value reminderCancellationURI
 */
export async function notifyPlayersTurn(
    notifications: Notification[],
    shouldQueueReminders: boolean
): Promise<Result<Record<string, string>>> {
    try {
        await sendNotifications(notifications);
        if (!shouldQueueReminders) return result({});
        return result(await queueReminders(notifications));
    } catch (e) {
        return failure(`Error notifying players: ${e}`);
    }
}

export async function notifyLobbyHost(gameId: string, joiningPlayerId: string, isLeaving: boolean): Promise<Result> {
    try {
        const game = await db.lobbyGame.fetch(gameId);
        if (!game) return failure('cannot notify for game that does not exist');
        if (!('sendNotifications' in game.config) || !game.config.sendNotifications) return success();
        const hostUser = await db.users.fetch(game.host);
        if (!hostUser) return failure(`host user doesn't exist`);
        const lobbyHostNotificationsResult = core.getNotificationsForLobbyHost(
            game,
            gameId,
            joiningPlayerId,
            isLeaving,
            hostUser
        );
        if (!lobbyHostNotificationsResult.success) return lobbyHostNotificationsResult;
        await sendNotifications(lobbyHostNotificationsResult.value);
        return success();
    } catch (e) {
        return failure(`Error notifying lobby host: ${e}`);
    }
}

export async function cancelReminder(reminderCancellationUris: string[]): Promise<Result<AxiosResponse[]>> {
    try {
        return result(
            await Promise.all(
                reminderCancellationUris.map((reminderCancellationUri) => Axios.post(reminderCancellationUri))
            )
        );
    } catch (e) {
        return failure(`Error cancelling reminders: ${e}`);
    }
}
