import { Result, result, success, failure } from '@playtime/database';
import { ActiveGame } from '@playtime/database/src/model/activeGame';
import { LobbyGame, User, GameAssignmentType, GameType } from '@playtime/database/src/model/lobby';

export function removePlayer(game: LobbyGame, userId: string): Result<boolean> {
    if (game.players) {
        delete game.players[userId];
    }

    // If host leaves, assign a new host
    const playerIds = Object.keys(game.players ?? {});
    if (playerIds.length < 1) {
        return result(true);
    } else if (playerIds.indexOf(game.host) < 0) {
        game.host = playerIds[0];
    }
    return result(false);
}

export function assignPlayerToGame(user: User, gameId: string, gameType: GameAssignmentType): Result {
    user.activeGameId = gameId;
    if (user.games?.find((game) => gameId === game.id)) return failure('Player already belongs to this game');
    user.games = [{ id: gameId, type: gameType }, ...(user.games ?? [])];
    return success();
}

export function unassignPlayerFromGame(user: User, gameId: string): Result {
    if (!user.games?.length) return failure('Cannot unassign player from game without belonging to any games');
    const gameIx = user.games.findIndex((game) => game.id === gameId);
    if (gameIx < 0) return failure("Tried unassigning player from a game that doesn't belong to the player");
    if (user.activeGameId === gameId) delete user.activeGameId;
    user.games.splice(gameIx, 1);
    return success();
}

export function unassignPlayerFromActiveGame(user: User): Result<string> {
    const gameId = user.activeGameId;
    if (!gameId) {
        return failure('Player not actively in a game');
    }

    delete user.activeGameId;
    const gameIx = user.games?.findIndex((game) => game.id === gameId);
    if (gameIx !== undefined && gameIx >= 0) user.games?.splice(gameIx, 1);
    return result(gameId);
}

/** Returns true if all players are inactive */
export function deactivatePlayer(game: ActiveGame, userId: string): Result<boolean> {
    if (!game.players) return result(true);

    if (game.players[userId]) game.players[userId].isActive = false;

    const usersTeamIx = game.teams.findIndex((team) => team.players.includes(userId));
    const usersTeam = game.teams[usersTeamIx];
    if (usersTeam) {
        usersTeam.players.splice(usersTeam.players.indexOf(userId), 1);
        if (!usersTeam.players.length) game.teams.splice(usersTeamIx, 1);
    }

    const activePlayerKvp = Object.entries(game.players).find(([, val]) => val.isActive);
    if (!activePlayerKvp) return result(true);
    if (userId === game.host) game.host = activePlayerKvp[0];
    return result(false);
}

export function activateGame(user: User, gameId: string) {
    if (!user.games?.length) return failure('Cannot activate game without belonging to any games');
    if (!user.games.find((game) => game.id === gameId))
        return failure("Tried activating game that doesn't belong to the player");
    user.activeGameId = gameId;
    return success();
}

export function setGameType(user: User, gameId: string, gameType: GameType) {
    if (!user.games?.length) return failure('Cannot set game without belonging to any games');
    const gameIx = user.games.findIndex((game) => game.id === gameId);
    if (gameIx < 0) return failure("Tried setting game type of a game that doesn't belong to the player");
    user.games[gameIx] = { id: gameId, type: gameType };
    return success();
}
