import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { CodenamesStats } from '@playtime/database/src/model/stats';
import { PieController, ArcElement, Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { NotOptional } from '../../../util/util';
import { SyncedComponentProps } from '../../hoc/sync';
import StatCard from '../../Stats/StatCard';
import { makeStyles } from 'tss-react/mui';

const darkenedRed = red[600];
const darkenedGreen = green[600];
const bystandardBeige = 'rgb(220, 213, 185)';
const black = 'rgb(0, 0, 0)';
const useStyles = makeStyles()(() => {
    const square = {
        height: 10,
        width: 10,
        display: 'inline-block',
    };
    return {
        redSquare: {
            ...square,
            backgroundColor: darkenedRed,
        },
        greenSquare: {
            ...square,
            backgroundColor: darkenedGreen,
        },
        beigeSquare: {
            ...square,
            backgroundColor: bystandardBeige,
        },
        blackSquare: {
            ...square,
            backgroundColor: black,
        },
        counts: {
            display: 'inline-block',
            paddingLeft: 4,
            paddingRight: 5,
        },
    };
});

const AgentDistribution: React.FunctionComponent<
    SyncedComponentProps<
        NotOptional<Pick<CodenamesStats, 'assassinCount' | 'bystandardCount' | 'enemyAgentCount' | 'streaks'>>
    >
> = (props) => {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const [chart, setChart] = useState<Chart | null>(null);
    const { classes } = useStyles();

    const totals = () => {
        if (
            props.assassinCount === null ||
            props.bystandardCount === null ||
            props.enemyAgentCount === null ||
            props.streaks === null
        )
            return null;
        if (
            props.assassinCount === undefined ||
            props.bystandardCount === undefined ||
            props.enemyAgentCount === undefined ||
            props.streaks === undefined ||
            Object.keys(props.streaks).length === 0
        )
            return undefined;
        return [
            Object.entries(props.streaks).reduce((prev, cur) => {
                return prev + Number(cur[0]) * cur[1].count;
            }, 0),
            props.bystandardCount,
            props.enemyAgentCount,
            props.assassinCount,
        ];
    };

    useEffect(() => Chart.register(PieController, ArcElement, ChartDataLabels), []);

    useEffect(() => {
        const context = canvasRef.current?.getContext('2d');
        const data = totals();
        if (canvasRef?.current && context && !chart && data) {
            const total = data.reduce((prev, cur) => cur + prev, 0);
            setChart(
                new Chart(context, {
                    type: 'pie',
                    options: {
                        plugins: {
                            datalabels: {
                                color: (context) => ([1, 2].indexOf(context.dataIndex) === -1 ? 'white' : 'black'),
                                font: {
                                    weight: 'bold',
                                },
                                padding: 6,
                                formatter: (val) => {
                                    return `${val} (${Math.round((val / total) * 100)}%)`;
                                },
                            },
                        },
                    },

                    data: {
                        labels: ['Ally Agent', 'Bystandard', 'Enemy Agent', 'Assassin'],
                        datasets: [
                            {
                                label: 'My First Dataset',
                                data: data,
                                backgroundColor: [darkenedGreen, bystandardBeige, darkenedRed, black],
                                hoverOffset: 14,
                            },
                        ],
                    },
                })
            );
        }
    }, [canvasRef.current, props.streaks]);

    const data = totals();

    return (
        <StatCard
            title="Accuracy"
            caption="Agent Distribution"
            toolTip="The total codenames guessed while you were clue giver."
        >
            {data === null ? (
                <CircularProgress />
            ) : data === undefined ? (
                <Typography variant="h3" style={{ color: '#555' }}>
                    None
                </Typography>
            ) : (
                <Box display="flex">
                    <Box height={200} width={200}>
                        <canvas ref={canvasRef}></canvas>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" alignItems="center">
                            <Box className={classes.greenSquare}></Box>
                            <Typography variant="caption" className={classes.counts}>
                                Ally Agent
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box className={classes.beigeSquare}></Box>
                            <Typography variant="caption" className={classes.counts}>
                                Bystandard
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box className={classes.redSquare}></Box>
                            <Typography variant="caption" className={classes.counts}>
                                Enemy Agent
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box className={classes.blackSquare}></Box>
                            <Typography variant="caption" className={classes.counts}>
                                Assassin
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}
        </StatCard>
    );
};

export default AgentDistribution;
