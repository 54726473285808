import { Badge, Box } from '@mui/material';
import { Paper } from '@mui/material';
import { Word } from '@playtime/database/src/model/codenames';
import * as React from 'react';
import { getColorSchemeRgb } from '../../../../back-end';
import { makeStyles } from 'tss-react/mui';

const useStyles = (
    darkFont: boolean,
    primaryColor: [number, number, number],
    secondaryColor?: [number, number, number]
) => {
    const rgbColors = getColorSchemeRgb(primaryColor, secondaryColor);
    const opacity = '.85';
    return makeStyles()({
        card: {
            width: '80px',
            height: '35px',
            cursor: 'pointer',
        },
        spyTypeBackground: {
            background: secondaryColor
                ? `linear-gradient(45deg, rgb(${rgbColors.secondary}, ${opacity}) 5%, rgb(${rgbColors.primary}, ${opacity}) 70%)`
                : `rgb(${rgbColors.primary}, ${opacity})`,
        },
        wordColor: {
            color: darkFont ? '#666' : '#fff',
        },
    });
};

export interface WordCardProps extends Word {
    onClick(): void;
    isPlayerClueGiver: boolean;
    revealed: boolean;
    numberOfVotes: number;
    primaryColor: [number, number, number];
    darkFont?: boolean;
    secondaryColor?: [number, number, number];
}

const WordCard: React.FunctionComponent<WordCardProps> = (props) => {
    const { classes } = useStyles(props.darkFont ?? false, props.primaryColor, props.secondaryColor)();
    const backgroundColor = props.isPlayerClueGiver || props.revealed ? classes.spyTypeBackground : '';
    const wordColor = props.isPlayerClueGiver || props.revealed ? classes.wordColor : '';

    const wordCard = (
        <Paper className={`${classes.card} ${backgroundColor}`}>
            <Box onClick={props.onClick} display="flex" alignItems="center" justifyContent="center" height="100%">
                {!props.revealed || !props.isPlayerClueGiver ? <span className={wordColor}>{props.word}</span> : null}
            </Box>
        </Paper>
    );
    const wordCardWithBadge =
        props.voters && props.voters.length > 0 ? (
            <Badge
                overlap="rectangular"
                badgeContent={
                    <>
                        {props.voters.length}/{props.numberOfVotes}
                    </>
                }
                color="secondary"
            >
                {wordCard}
            </Badge>
        ) : (
            wordCard
        );
    return wordCardWithBadge;
};

export default WordCard;
