import * as core from '../../business/codenames/gameplay';
import * as historicalGames from '../historicalGames';
import * as stats from './codenamesStats';
import * as messaging from '../messaging';
import * as playerStats from '../playerStats';
import * as lobby from '../lobby';
import { gameNotFoundMsg } from '../game';
import { getRandomWords } from './wordCollection';
import { generateTeams } from '../lobby';
import db from '../../databases';
import { inTranIfDefined, failure, success, CodenamesGame } from '@playtime/database';
import { Word, Clue } from '@playtime/database/src/model/codenames';
import { GameType } from '@playtime/database/src/model/lobby';

export async function notifyPlayers(gameId: string, gameOver?: boolean) {
    const game = await db.codenamesGame.fetch(gameId);
    if (!game) return failure('cannot notify for game that does not exist');
    const gameUsers = await lobby.getUsers(Object.keys(game.players));
    if (!gameUsers.success) return gameUsers;
    if (!game.config.sendNotifications) return success();
    await cancelGameReminders(game);
    const gameNotificationsResult = core.getNotificationsForGame(game, gameId, gameUsers.value);
    if (!gameNotificationsResult.success) return gameNotificationsResult;
    const cancelUrisResult = await messaging.notifyPlayersTurn(gameNotificationsResult.value, !gameOver);
    if (!cancelUrisResult.success) return cancelUrisResult;
    if (Object.keys(cancelUrisResult.value).length)
        await inTranIfDefined(
            db.codenamesGame,
            gameId,
            (game) => core.setCancellationUris(game, cancelUrisResult.value),
            gameNotFoundMsg
        );
    return success();
}

export async function readyClueGiver(gameId: string, userId: string) {
    const game = await db.codenamesGame.fetch(gameId);
    if (!game) return failure("tried ready process for game that doesn't exist");
    const randomWords = await getRandomWords(game.config.numberOfWords);
    const readyClueGiverResult = await inTranIfDefined(
        db.codenamesGame,
        gameId,
        (game) => core.readyClueGiver(game, userId, randomWords),
        gameNotFoundMsg
    );
    if (!readyClueGiverResult.success || !readyClueGiverResult.value.allReady) return readyClueGiverResult;
    await notifyPlayers(gameId);
    return readyClueGiverResult;
}

export async function selectWord(gameId: string, userId: string, word: Word) {
    const selectWordResult = await inTranIfDefined(
        db.codenamesGame,
        gameId,
        (game) => core.selectWord(game, userId, word),
        gameNotFoundMsg
    );
    if (!selectWordResult.success) return selectWordResult;
    if (selectWordResult.value.reminderCancellationUri)
        messaging.cancelReminder([selectWordResult.value.reminderCancellationUri]);
    if (selectWordResult.value.streakEnded) {
        await stats.streaked(
            selectWordResult.value.players,
            selectWordResult.value.clueGiver,
            selectWordResult.value.streak,
            selectWordResult.value.streakEnder
        );
        await notifyPlayers(gameId, selectWordResult.value.gameEnded);
    }
    if (!selectWordResult.value.gameEnded) return selectWordResult;

    // game over
    const recordGameResults = await historicalGames.recordGame(
        selectWordResult.value.game,
        [selectWordResult.value.winningTeamIndex],
        GameType.Codenames,
        { config: selectWordResult.value.game.config }
    );
    if (!recordGameResults.success) return recordGameResults;
    const gameOverResults = await stats.gameOver(
        selectWordResult.value.playersOutcome,
        recordGameResults.value.playersNewElo,
        recordGameResults.value.historicalGameId,
        selectWordResult.value.clueGivers
    );
    return gameOverResults;
}

export async function passTurn(gameId: string, userId: string) {
    return await inTranIfDefined(db.codenamesGame, gameId, (game) => core.passTurn(game, userId), gameNotFoundMsg);
}

export async function endGuessing(gameId: string, userId: string) {
    const passTeamResults = await inTranIfDefined(
        db.codenamesGame,
        gameId,
        (game) => core.endGuessing(game, userId),
        gameNotFoundMsg
    );
    if (!passTeamResults.success) return passTeamResults;
    await notifyPlayers(gameId);
    stats.streaked(
        passTeamResults.value.players,
        passTeamResults.value.clueGiver,
        passTeamResults.value.streak,
        'pass'
    );
    return passTeamResults;
}

export async function giveClue(
    gameId: string,
    clue: string,
    count: Clue['count'],
    clueGiver: string,
    clueGiverTeam: number
) {
    const giveClueResult = await inTranIfDefined(
        db.codenamesGame,
        gameId,
        (game) => core.giveClue(game, clue, count, clueGiver, clueGiverTeam),
        gameNotFoundMsg
    );
    if (!giveClueResult.success) return giveClueResult;
    return await notifyPlayers(gameId);
}

export async function nextClueGiver(gameId: string) {
    const nextClueGiverResult = await inTranIfDefined(
        db.codenamesGame,
        gameId,
        (game) => core.nextClueGiver(game),
        gameNotFoundMsg
    );
    if (!nextClueGiverResult.success) return nextClueGiverResult;
    await notifyPlayers(gameId);
    return nextClueGiverResult;
}

export async function changeTeams(gameId: string) {
    const game = await db.codenamesGame.fetch(gameId);
    if (!game) return failure('cannot change teams. game does not exist');

    const playerIds = Object.keys(game.players);
    const playersEloResult = await playerStats.getPlayersElo(playerIds, GameType.Codenames);
    if (!playersEloResult.success) return playersEloResult;
    const placeholderEloResult = await playerStats.getPlaceholderElo(
        playerIds.length,
        game.config.numberOfTeams,
        GameType.Codenames
    );
    if (!placeholderEloResult.success) return placeholderEloResult;

    const nextClueGiverResult = await inTranIfDefined(
        db.codenamesGame,
        gameId,
        (game) => {
            return core.changeTeams(
                game,
                playersEloResult.value,
                placeholderEloResult.value,
                generateTeams(game.config.numberOfTeams)
            );
        },
        gameNotFoundMsg
    );
    if (!nextClueGiverResult.success) return nextClueGiverResult;
    await notifyPlayers(gameId);
    return nextClueGiverResult;
}

export async function joinAfterGameStart(gameId: string, userId: string, displayName: string) {
    const game = await db.codenamesGame.fetch(gameId);
    if (!game) return failure('cannot late join this game. game does not exist');
    if (!game.config.allowLateJoin) return failure('cannot late join this game. game is not set to allow late joins');

    const playersEloResult = await playerStats.getPlayersElo(
        [...Object.keys(game.players), userId],
        GameType.Codenames
    );
    if (!playersEloResult.success) return playersEloResult;

    const joinAfterGameStartResult = await inTranIfDefined(
        db.codenamesGame,
        gameId,
        (game) => core.joinAfterGameStart(game, userId, displayName, playersEloResult.value),
        gameNotFoundMsg
    );
    if (!joinAfterGameStartResult.success) return joinAfterGameStartResult;

    return lobby.assignPlayerToGame(gameId, userId);
}

async function cancelGameReminders(game: CodenamesGame) {
    const reminderCancellations = core.getReminderCancellations(game);
    await messaging.cancelReminder(reminderCancellations);
}
