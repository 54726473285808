import { DatabaseReference } from "firebase/database";
import { Database } from "..";
import { FirebaseDatabase } from "../core";
import { CodenamesWord } from "../model/codenamesWordCollection";
import { Reference } from "@firebase/database-types";

export function getCodenamesWordCollectionDb(
  getDbRef: () => DatabaseReference | Reference
): Database<CodenamesWord, string> {
  return new FirebaseDatabase<CodenamesWord, string>(
    getDbRef,
    "codenamesWordCollection"
  );
}
