import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Fingerprint, FindInPage } from '@mui/icons-material';
import { CodenamesGame, CodenamesTeam, Clue, Guess, isClue } from '@playtime/database/src/model/codenames';
import * as React from 'react';
import { getTextColor } from '../../../../common/styles';
import { getColor } from '../../utility';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{
    logTeam: Pick<CodenamesTeam, 'primaryColor' | 'secondaryColor' | 'darkFont' | 'name'>;
    wordColor?: ReturnType<typeof getColor>;
}>()((_, { logTeam, wordColor }) => {
    return {
        textColor: getTextColor(logTeam.darkFont ?? false, logTeam.primaryColor, logTeam.secondaryColor),
        wordColor: !wordColor
            ? {}
            : getTextColor(wordColor?.darkFont ?? false, wordColor.primaryColor, wordColor.secondaryColor),
    };
});

interface LogProps {
    log: Required<CodenamesGame>['gameLog'][number];
    logTeam: Pick<CodenamesTeam, 'primaryColor' | 'secondaryColor' | 'darkFont' | 'name'>;
    wordColor?: ReturnType<typeof getColor>;
}

const Log: React.FunctionComponent<LogProps> = (props) => {
    const { classes } = useStyles(props);

    const clueLog = (log: Clue) => (
        <ListItemText>
            <span className={classes.textColor}>{log.clueGiverName}</span> gave the clue:{' '}
            <span className={classes.textColor} style={{ fontWeight: 1000 }}>
                {log.clue}
            </span>
            ,{' '}
            <span className={classes.textColor} style={{ fontWeight: 1000 }}>
                {log.count}
            </span>
        </ListItemText>
    );

    const guessLog = (log: Guess) => (
        <ListItemText>
            <span className={classes.textColor}>{props.logTeam.name}</span> guessed{' '}
            <span className={classes.wordColor} style={{ fontWeight: 1000 }}>
                {log.word}
            </span>
        </ListItemText>
    );

    return (
        <ListItem>
            <ListItemIcon>{isClue(props.log) ? <Fingerprint /> : <FindInPage />}</ListItemIcon>
            {isClue(props.log) ? clueLog(props.log) : guessLog(props.log)}
        </ListItem>
    );
};

export default Log;
