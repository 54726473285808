import { GameType } from '@playtime/database/src/model/lobby';

export const HOME_ROUTE = '/';
export const CODENAMES_ROUTE = '/codenames';
export const FISHBOWL_ROUTE = '/fishbowl';
export const STATS_ROUTE = '/stats';
export const MANAGEMENT_ROUTE = '/management';
export const SINGLESIGNON_ROUTE = '/single-sign-on';

export function getGameRoute(gameType: GameType) {
    if (gameType === GameType.Fishbowl) return FISHBOWL_ROUTE;
    return CODENAMES_ROUTE;
}
