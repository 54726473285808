import { Auth, User } from 'firebase/auth';
import { IStore } from '..';

export const checkForUser = (state: IStore): User | null => {
    return state.auth.user;
};

export const getUser = (state: IStore): User => {
    const user = state.auth.user;
    if (user == null) throw new Error('User not logged in');
    return user;
};

export const getFirebaseAuth = (state: IStore): Auth | null => {
    return state.auth.firebaseAuth;
};
