import assert from "assert";
import { DataTransactional } from "./contract";
import { Result, failure } from "./result";

export type Action<T, R> = (obj: T) => Result<R>;

export async function inTransaction<T, K, R>(
  db: DataTransactional<T, K>,
  key: K,
  action: Action<{ obj: T | undefined }, R>
): Promise<Result<R>> {
  let result: Result<R> | undefined;
  await db.transaction(key, (obj: T | undefined) => {
    const ref = { obj };
    result = action(ref);
    return ref.obj;
  });
  assert(result, "Result should be set during transaction");
  return result;
}

export function inTranIfDefined<T, K, R>(
  db: DataTransactional<T, K>,
  key: K,
  action: Action<T, R>,
  message?: string
): Promise<Result<R>> {
  return inTransaction(db, key, (ref) => {
    return ref.obj === undefined
      ? failure(message ?? "Obj not defined")
      : action(ref.obj);
  });
}

export function inTranDefault<T, K, R>(
  db: DataTransactional<T, K>,
  key: K,
  action: Action<T, R>,
  defaultValue: T
): Promise<Result<R>> {
  return inTransaction(db, key, (ref) => {
    if (ref.obj === undefined) {
      ref.obj = defaultValue;
    }
    return action(ref.obj);
  });
}
