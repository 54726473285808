import { Immutable } from '../../../util/util';
import { addPlaceholderPlayers, populatePhrasePool } from './gameplayCore';
import { convertPhraseToKey } from '../pure/pure';
import { balanceTeams } from '../pure/elo';
import { PLACEHOLDER_ID_PREFIX } from '../../api/fishbowl/fishbowlStats';
import { generateTeams } from '../..';
import { Result, success, failure } from '@playtime/database';
import { FishbowlConfig, FishbowlGame } from '@playtime/database/src/model/fishbowl';
import { LobbyGame, GameType } from '@playtime/database/src/model/lobby';

export function validateFishbowlConfig(config: Immutable<FishbowlConfig>): Result {
    if (isNaN(config.freeSkips) || config.freeSkips < 0) {
        return failure('Free skips cannot be negative');
    }
    if (isNaN(config.skipPenaltySeconds) || config.skipPenaltySeconds < 0) {
        return failure('Skip penalty in seconds cannot be negative');
    }
    if (isNaN(config.minContestVotes) || config.minContestVotes < 0) {
        return failure('Minimum contest votes cannot be negative');
    }
    if (isNaN(config.numberOfTeams) || config.numberOfTeams <= 0) {
        return failure('Number of teams must be positive');
    }
    if (isNaN(config.phrasesPerPlayer) || config.phrasesPerPlayer <= 0) {
        return failure('Phrases per player must be positive');
    }
    if (isNaN(config.secondsPerTurn) || config.secondsPerTurn <= 0) {
        return failure('Seconds per turn must be positive');
    }
    if (config.rounds.length <= 0) {
        return failure('There must be at least 1 round');
    }
    return success();
}

export function addPhrase(game: LobbyGame<GameType.Fishbowl>, userId: string, phrase: string): Result {
    const player = game.players?.[userId];
    if (!player) {
        return failure('Player not in game');
    }
    const phraseKey = convertPhraseToKey(phrase);
    if (Object.keys(player.phrases ?? {}).length >= game.config.phrasesPerPlayer) {
        return failure('Player has entered too many phrases');
    }
    player.phrases = { ...player.phrases, [phraseKey]: phrase };
    return success();
}

export function removePhrase(game: LobbyGame<GameType.Fishbowl>, userId: string, phrase: string): Result {
    const phrases = game.players?.[userId]?.phrases;
    if (!phrases) {
        return failure('No phrases for player');
    }
    const phraseKey = convertPhraseToKey(phrase);
    delete phrases[phraseKey];
    return success();
}

export function activateFishbowlGame(
    games: { lobbyGame?: LobbyGame<GameType.Fishbowl>; activeGame?: FishbowlGame },
    teamConfig: ReturnType<typeof generateTeams>,
    playersElo: Record<string, number>,
    placeholderElo: { id: string; elo: number } | null
): Result {
    const gameToStart = games.lobbyGame;
    if (gameToStart === undefined) {
        return failure('Game to start does not exist');
    }
    if (games.activeGame !== undefined) {
        return failure('Active game with same id already exists');
    }
    if (gameToStart.config.rounds.length < 1) {
        return failure('Expected positive number of rounds');
    }
    const players: FishbowlGame['players'] = {};
    const playerList = Object.entries(gameToStart.players ?? {});
    playerList.forEach(([id, player]) => (players[id] = { ...player, isActive: true }));
    if (!players) {
        return failure('Game to start has no players');
    }

    // Assign teams
    const numTeams = gameToStart.config.numberOfTeams;
    if (numTeams < 1) {
        return failure('Expected positive number of teams');
    }
    if (teamConfig.length !== numTeams) {
        return failure('Unexpected number of team names');
    }

    if (Object.keys(playersElo).length < playerList.length) return failure('Not all players have elo assignment');
    addPlaceholderPlayers(playersElo, placeholderElo, playerList.length % numTeams);
    const playersAndEloWithPlaceholders = Object.entries(playersElo);
    if (playerList.length < numTeams) return failure('Fewer players than teams');
    const balancedTeamsWithPlaceholders = balanceTeams(playersAndEloWithPlaceholders, gameToStart.config.numberOfTeams);
    const balancedTeams = balancedTeamsWithPlaceholders.map((x) =>
        x.filter((y) => y.indexOf(PLACEHOLDER_ID_PREFIX) === -1)
    );

    const teams = balancedTeams
        .map((team, teamIndex) => ({
            scores: gameToStart.config.rounds.map(() => 0),
            ...teamConfig[teamIndex],
            playerTurn: 0,
            players: team,
        }))
        .sort((a, b) => a.players.length - b.players.length);

    // Initialize active game
    const game: FishbowlGame = {
        name: gameToStart.name,
        host: gameToStart.host,
        config: gameToStart.config,
        gameType: GameType.Fishbowl,
        players,
        round: 0,
        teamTurn: 0,
        teams,
        skipsUsed: 0,
        turn: 0,
    };
    populatePhrasePool(game);

    // Move game from lobby to active
    delete games.lobbyGame;
    games.activeGame = game;
    return success();
}
